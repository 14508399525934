import mapDesigner from './mapDesigner';
const defaultDesigners = Array(7).fill({ designerId: ' ', designerLabel: ' ', designerName: ' ', href: '/', productCount: null });

/**
 * Retrieves designers + sale designers from state.
 * @param {object} state
 * @param {string} locale (eg. "en-gb")
 * @param {string} urlTemplate (eg. "/{{LOCALE}}/mens{{SEO_URLKEYWORD}}")
 * @param {bool} isSale
 * @returns {array} - designers
 */
export default function getDesigners(state, locale, urlTemplate, isSale = false) {
    const { response, error, loading } = state.daz.designers;
    const { designers, designersWithSale } = response || {};

    if (loading) {
        return defaultDesigners;
    }
    if (response === null || error) {
        return null;
    }
    if (!designers && !designersWithSale) {
        return defaultDesigners;
    }

    const designersToMap = isSale ? designersWithSale : designers;

    return designersToMap.map((designer) => {
        return mapDesigner(designer.identifier, designer, locale, urlTemplate, isSale);
    });
}
