import { formatPrice } from '@ynap/intl-formatters';
import { MULTI_SELECT, SCHEMA_SELECT, SINGLE_SELECT } from '../constants';
import { createSelector } from 'reselect';
import { getCategoryIdValue } from './getCategoryLabels';
import { default as getSelectedCategory } from './getSelectedCategory';
import queryStringUtils from 'query-string';

const DEFAULT_QUERY_KEY = 'facet';
const ADD_CATEGORY_KEY = 'addCategoryId';
const CATEGORY_KEY = 'categoryId';
const PRICE_FILTER_FACET_PREFIX = 'price';

export default createSelector(
    [
        (state) => state.plp?.listing?.error,
        (state) => state.plp?.listing?.response,
        (state) => state.plp?.selectedSchemas,
        (state) => state.config,
        (state, props) => getSelectedCategory(state, props?.routeKey),
        (state) => state.appState?.locale,
        (state, props) => props?.messages,
    ],
    getFiltersInternal,
);

export function getFiltersInternal(error, response, selectedSchemas, config, selectedCategory, locale, messages) {
    const { selectedCategories = [], pageType } = selectedCategory || {};
    if (error || response === null) {
        return null;
    }
    const { body = {}, path } = response || {};
    const { facets: rawFacets = [] } = body;
    const plpConfig = config?.head?.plp || {};
    const { categoryId, facet: facetFilterConfig } = plpConfig;
    const filterBySeoPath = pageType !== 'SEARCH';
    const facets = orderFacetsBasedOnConfig(rawFacets, facetFilterConfig);

    const orderedFilters = {};
    facets.forEach((facet) => {
        if (facet.schemaIdentifier) {
            addSchemaFilter(orderedFilters, facet, selectedSchemas);
        } else if (categoryId && facet.identifier === categoryId) {
            const categoryFilters = getCategoryFilter(facet, selectedCategories, filterBySeoPath, facetFilterConfig?.ignoreCategories, pageType);
            categoryFilters.forEach((filter) => (orderedFilters[filter.id] = filter));
        } else if (facet.identifier.indexOf(PRICE_FILTER_FACET_PREFIX) !== -1) {
            if (facetFilterConfig?.togglePriceFilterSlider) {
                orderedFilters[facet.identifier] = getPriceFilterData(facet);
            } else {
                const filterProps = getFilter(
                    facet.label,
                    facet.identifier,
                    '',
                    facet.entry,
                    MULTI_SELECT,
                    DEFAULT_QUERY_KEY,
                    'value',
                    null,
                    facet.currency,
                    locale,
                    messages,
                );
                const priceVariantData = getPriceFilterData(facet, path);
                orderedFilters[facet.identifier] = {
                    ...filterProps,
                    appliedValues: priceVariantData.appliedValues?.length ? priceVariantData.appliedValues : filterProps.appliedValues,
                    variant: priceVariantData,
                };
            }
        } else {
            orderedFilters[facet.identifier] = getFilter(
                facet.label,
                facet.identifier,
                '',
                facet.entry,
                MULTI_SELECT,
                DEFAULT_QUERY_KEY,
                'value',
                null,
                facet.currency,
                locale,
                messages,
            );
        }
    });

    return Object.values(orderedFilters).reduce((filters, filter) => {
        let values;
        if (filter.schemas) {
            const selectedSchema = filter.schemas.find((s) => s.selected);
            if (!selectedSchema) return filters;

            ({ values } = selectedSchema);
        } else {
            ({ values } = filter);
        }

        return values.length > 0 ? [...filters, filter] : filters;
    }, []);
}

function addSchemaFilter(orderedFilters, facet, selectedSchemas) {
    // if we already have a filter with this schema then add to that one.
    if (orderedFilters[facet.identifier]) {
        const schemaFilter = getSchemaFilter(facet.schemaLabel, facet.schemaIdentifier, facet.entry, facet.selected, selectedSchemas[facet.identifier]);
        if (schemaFilter.selected) {
            const appliedValues = [];
            const appliedLabels = [];

            schemaFilter.values.forEach((value) => {
                if (value.isApplied) {
                    appliedValues.push(value.value);
                    appliedLabels.push(value.label);
                }
            });
            orderedFilters[facet.identifier].appliedValues = appliedValues;
            orderedFilters[facet.identifier].appliedLabels = appliedLabels;
            orderedFilters[facet.identifier].values = schemaFilter.values;
        }
        orderedFilters[facet.identifier].schemas.push(schemaFilter);
    } else {
        orderedFilters[facet.identifier] = getSchemaObject(facet, selectedSchemas);
    }
}

function getSchemaObject(facet, selectedSchemas, queryKey) {
    const schemaFilter = getSchemaFilter(facet.schemaLabel, facet.schemaIdentifier, facet.entry, facet.selected, selectedSchemas[facet.identifier]);
    const appliedValues = [];
    const appliedLabels = [];

    if (schemaFilter.selected) {
        schemaFilter.values.forEach((value) => {
            if (value.isApplied) {
                appliedValues.push(value.value);
                appliedLabels.push(value.label);
            }
        });
    }

    return {
        id: facet.identifier,
        label: facet.label ? facet.label : facet.schemaLabel,
        type: SCHEMA_SELECT,
        queryKey: queryKey || DEFAULT_QUERY_KEY,
        appliedValues: schemaFilter.selected ? appliedValues : [],
        appliedLabels: schemaFilter.selected ? appliedLabels : [],
        values: schemaFilter.selected ? schemaFilter.values : [],
        schemas: [schemaFilter],
    };
}

function getFilter(label, id, value, children, selectType, queryKey, filterValueKey, selectedCategories, currency, locale, messages) {
    const appliedValues = [];
    const returnedValues = [];
    const appliedLabels = [];

    children.forEach((child) => {
        const label = child.label ? child.label : child.lower && getFormatedPriceRangeLabel(child, currency, locale, messages);

        if (selectedCategories && selectedCategories.find((c) => child.identifier === c.identifier)) {
            child.selected = true;
        }

        if (child.selected) {
            const childValue = selectedCategories ? child.seo.seoURLKeyword : child[filterValueKey];
            appliedValues.push(childValue);
            appliedLabels.push(label);
        }
        if (_showableEntry(child)) {
            returnedValues.push({
                label,
                count: child.count,
                value: selectedCategories ? child.seo.seoURLKeyword : child[filterValueKey],
                ...(child.numberOfVisibleProducts && { numberOfVisibleProducts: child.numberOfVisibleProducts }),
                ...(child.categoryId && { categoryId: child.categoryId }),
                ...(child.identifier && { identifier: getCategoryIdValue(child.identifier) }),
                ...(child.seo && { seo: child.seo }),
                ...(child.swatch && {
                    swatch: {
                        ...(child.swatch.HEX && { hex: child.swatch.HEX }),
                        ...(child.swatch.URL && { url: child.swatch.URL }),
                    },
                }),
                isApplied: !!child.selected,
            });
        }
    });
    return {
        label,
        id,
        value,
        type: selectType || MULTI_SELECT,
        queryKey: queryKey || DEFAULT_QUERY_KEY,
        appliedValues,
        appliedLabels,
        values: returnedValues,
        isFilterAllApplied: appliedValues.length === returnedValues.length ? true : false,
    };
}

export function getFormatedPriceRangeLabel(option, currency, locale, messages) {
    const { lower, upper } = option;
    const { label } = currency;
    const { under, over } = messages?.productListing?.priceFilter || {};
    const lowerPrice = formatPrice(lower, label, locale);
    const upperPrice = formatPrice(upper, label, locale);

    let priceLabel = `${lowerPrice} - ${upperPrice}`;

    if (lower === '*' && under) {
        priceLabel = under({ price: upperPrice });
    } else if (upper === '*' && over) {
        priceLabel = over({ price: lowerPrice });
    }

    return priceLabel;
}

// todo remove after dual running of TON is over and fix in WCS
function orderFacetsBasedOnConfig(facets, facetFilters) {
    if (!facetFilters || !facetFilters.order) {
        return facets;
    }

    const sortableFacets = facetFilters.order.reduce((sortedFacets, facetIdentifier) => {
        const facet = facets.find(({ identifier }) => identifier === facetIdentifier);
        return facet ? [...sortedFacets, facet] : sortedFacets;
    }, []);

    const unsortedFacets = facets.filter(({ identifier }) => facetFilters.order.includes(identifier) === false);
    return sortableFacets.concat(unsortedFacets);
}

function getSchemaFilter(schemaLabel, schemaIdentifier, values, selected, stateSelected) {
    let calculatedSelected = false;

    // the selected schema in the state is this one or there isn't a selected schema in the state and WCS has marked this as selected
    if (stateSelected === schemaIdentifier || (!stateSelected && selected)) {
        calculatedSelected = true;
    }

    const entries = values.reduce((entries, entry) => {
        if (_showableEntry(entry)) {
            return [
                ...entries,
                {
                    label: entry.label,
                    count: entry.count,
                    value: entry.value,
                    ...(entry.swatch && {
                        swatch: {
                            ...(entry.swatch.HEX && { hex: entry.swatch.HEX }),
                            ...(entry.swatch.URL && { url: entry.swatch.URL }),
                        },
                    }),
                    isApplied: !!entry.selected,
                    identifier: entry.identifier,
                },
            ];
        }
        return entries;
    }, []);

    return {
        label: schemaLabel,
        value: schemaIdentifier,
        id: schemaIdentifier,
        selected: calculatedSelected,
        values: entries,
    };
}

function findSelectedSubCategory(subCategories, selectedCategories) {
    return subCategories.find((subCategory) => {
        return subCategory.selected || selectedCategories.find((selected) => subCategory.identifier === selected.identifier);
    });
}

function isCategoryOrChildSelected(category) {
    return category.selected || category?.children?.find(isCategoryOrChildSelected);
}

export function getCategoryFilter(categoryFacet, selectedCategories, filterBySeoPath, ignoreCategories = [], pageType) {
    let label = '';
    let id = '';
    let value = '';
    let subCategories = [];
    const filters = [];
    let selectedSubCategory;
    let categoryEntry = categoryFacet.entry;

    if (ignoreCategories.includes(categoryFacet.entry?.[0]?.seo?.seoURLKeyword)) {
        if (categoryFacet.entry[0]?.children?.length > 1) {
            const selectedCategory = categoryFacet.entry[0]?.children?.filter(isCategoryOrChildSelected);

            if (selectedCategory?.length) {
                categoryEntry = selectedCategory;
            }
        } else if (categoryFacet.entry[0]?.children?.[0]?.children) {
            const subCategory = categoryFacet.entry[0]?.children?.[0];

            if (ignoreCategories.includes(subCategory?.seo?.seoURLKeyword)) {
                categoryEntry = subCategory.children[0]?.children || [];
            } else {
                // If category has been added to config.head.plp.facet.ignoreCategories eg. /designer, /list
                // Step in 2 levels to find correct categories https://jira.nap/browse/WCSSD-3970
                categoryEntry = subCategory.children;
            }
        }
    } else if (categoryFacet.entry.length === 1 && selectedCategories?.[0]?.identifier === categoryFacet.entry?.[0]?.identifier && pageType !== 'STANDARD') {
        // full tree is returned (WCS 4 tier categories), jump into children
        categoryEntry = categoryFacet.entry[0].children;
    }

    /* Fix for https://jira.nap/browse/WCSSD-4467, stops root category being in facets /designer in /designer/clothing level */
    const entries =
        categoryEntry.length > 1
            ? categoryEntry
                  .filter((entry) => !ignoreCategories?.includes?.(entry.seo.seoURLKeyword))
                  .filter((entry) => selectedCategories?.[0]?.identifier !== entry.identifier)
            : categoryEntry;
    /* end fix for https://jira.nap/browse/WCSSD-4467 */

    const showableCategoryEntriesCount = showableEntriesCount(entries);

    const selected = selectedCategories.length > 0 ? selectedCategories[0] : {};
    const topCategory = getFilter(
        categoryFacet.label,
        categoryFacet.identifier,
        selected.seoURLKeyword,
        entries,
        SINGLE_SELECT,
        CATEGORY_KEY,
        'seoURLKeyword',
        selectedCategories,
        ignoreCategories,
    );

    //for 3 tier listing, like /clothing or /shoes, it only has one entry
    //prepare the data and skip to next lvl category
    if (showableCategoryEntriesCount === 1) {
        label = categoryFacet.label;
        id = categoryFacet.identifier;
        value = categoryEntry[0].seo.seoURLKeyword;
        subCategories = categoryEntry[0].selected ? categoryEntry[0].children : [];

        // Only add top category filter if its not same as current category
        if (!topCategory.appliedValues.includes(topCategory.value)) {
            label = categoryEntry[0].label;
            filters.push(topCategory);
        }
    } else if (showableCategoryEntriesCount > 1) {
        //for 4 tier listing like /list/the-instagram-shop topCategory will be /list/the-instagram-shop

        // If category has been added to config.head.plp.facet.ignoreCategories eg. /sale,
        // applied value will be same as current category hide 'All' in single select filter list.
        if (topCategory.appliedValues.includes(topCategory.value)) {
            topCategory.hideAllSelect = true;
        }

        //check if any sub categories are selected
        selectedSubCategory = findSelectedSubCategory(categoryEntry, selectedCategories);

        if (selectedSubCategory) {
            subCategories = selectedSubCategory.children;
            label = selectedSubCategory.label;
            id = selectedSubCategory.identifier;
            value = selectedSubCategory.seo.seoURLKeyword;
        }

        filters.push(topCategory);
    }

    if (subCategories) {
        const showableSubCategoryEntriesCount = showableEntriesCount(subCategories);

        // To show the third level categories on Designers page if the second level has only 1 category
        if (showableSubCategoryEntriesCount == 1) {
            const subCategoryIndex = subCategories.findIndex((subCategory) => _showableEntry(subCategory));
            subCategories[subCategoryIndex].selected = true;
        } else if (showableSubCategoryEntriesCount > 1) {
            //only show sub cateogries if there are more than 1
            //firstLvlCategory will be category like: /clothing, /the-instagram-shop/clothing or /designers/gucci/bags
            const firstLvlCategory = getFilter(
                label,
                id,
                value,
                subCategories,
                SINGLE_SELECT,
                CATEGORY_KEY,
                'seoURLKeyword',
                selectedCategories,
                ignoreCategories,
            );
            filters.push(firstLvlCategory);
        }

        //again check if any sub categories are selected
        selectedSubCategory = findSelectedSubCategory(subCategories, selectedCategories);

        if (selectedSubCategory) {
            // again only show sub cateogries if there are more than 1
            if (showableEntriesCount(selectedSubCategory.children) > 1) {
                //secondLvlCategory will be category like: /clothing/dresses, /the-instagram-shop/clothing/dresses or /designers/gucci/bags/tote
                label = selectedSubCategory.label;
                id = selectedSubCategory.identifier;
                value = filterBySeoPath ? selectedSubCategory.seo.seoURLKeyword : selectedSubCategory.categoryId;
                const secondLvlCategory = getFilter(
                    label,
                    id,
                    value,
                    selectedSubCategory.children,
                    MULTI_SELECT,
                    ADD_CATEGORY_KEY,
                    'categoryId',
                    selectedCategories,
                    ignoreCategories,
                );
                filters.push(secondLvlCategory);
            }
        }
    }
    return filters;
}

export function _showableEntry(entry) {
    return entry.count > 0 || entry.selected;
}
export function showableEntriesCount(entries) {
    return entries ? entries.reduce((count, entry) => (_showableEntry(entry) ? count + 1 : count), 0) : 0;
}

const parsePrice = ({ amount, divisor }) => {
    return Math.round(amount / divisor);
};

export const getCurrentRange = (path) => {
    const { query } = queryStringUtils.parseUrl(path);
    const { minPrice, maxPrice } = query || {};
    const rangeLower = minPrice && Number(minPrice) ? Number(minPrice) : 0;
    const rangeHigher = maxPrice && Number(maxPrice) ? Number(maxPrice) : null;
    return [rangeLower, rangeHigher];
};

export const getPriceFilterData = (facet, location) => {
    const { label, lower, upper, currency, entry, filteredBy } = facet;

    let defaultPriceMax;
    let step;
    switch (currency.label) {
        case 'AUD':
            defaultPriceMax = 6000;
            step = 100;
            break;
        case 'SAR':
        case 'AED':
            defaultPriceMax = 15000;
            step = 250;
            break;
        case 'RUB':
            defaultPriceMax = 260000;
            step = 4500;
            break;
        case 'HKD':
            defaultPriceMax = 30000;
            step = 500;
            break;
        case 'JPY':
            defaultPriceMax = 400000;
            step = 6500;
            break;
        default:
            defaultPriceMax = parsePrice(upper);
            step = upper.divisor;
    }
    const plpMinPrice = facet?.lower?.amount ? parsePrice(facet.lower) : 0;
    const plpMaxPrice = facet?.upper?.amount ? parsePrice(facet.upper) : defaultPriceMax;
    const filter = {
        currency,
        id: PRICE_FILTER_FACET_PREFIX,
        querykey: PRICE_FILTER_FACET_PREFIX,
        label,
        lower,
        upper,
        values: entry,
        ...(filteredBy && { filteredBy }),
        defaultPriceMax,
        plpMaxPrice,
        plpMinPrice,
        step,
    };
    const range = location && filter ? getCurrentRange(location, plpMinPrice) : [];
    const appliedValues = range.length && filteredBy ? range : [];
    const appliedLabels = range.length && filteredBy ? [`${currency.symbol}${range[0]} - ${currency.symbol}${range[1]}`] : [];
    return {
        ...filter,
        appliedValues,
        appliedLabels,
    };
};
