import getCanonicalSEOPath from './getCanonicalSEOPath';
import { raygun } from '@ynap/clientside-monitoring';

export const checkRedirect = (seoData, match, pdpUrlTemplate, locale) => {
    const selectedSizeSEO = seoData.selectedProductSizeSEO;
    const selectedColourSEO = seoData.selectedProductColourSEO;
    const productSEO = seoData.productSEO;
    const currentPid = match.params.PART_NUMBER;

    let redirectUrl,
        metaIndex,
        productLvlPid = false;

    if (matchPid(currentPid, selectedSizeSEO.partNumber, selectedSizeSEO.legacyId)) {
        redirectUrl = getRedirectUrl(currentPid, selectedSizeSEO, pdpUrlTemplate, locale);
        //dont index size pid because there is no seo data
        metaIndex = false;
    } else if (matchPid(currentPid, selectedColourSEO.partNumber, selectedColourSEO.legacyId)) {
        redirectUrl = getRedirectUrl(currentPid, selectedColourSEO, pdpUrlTemplate, locale);
        //have to index product colour because it's canonical
        metaIndex = true;
    } else if (matchPid(currentPid, productSEO.partNumber, productSEO.legacyId)) {
        redirectUrl = getRedirectUrl(currentPid, productSEO, pdpUrlTemplate, locale);
        metaIndex = setIndex(productSEO.title, productSEO.seoURLKeyword);
        productLvlPid = true;
    }
    const shouldRedirect = redirectUrl && redirectUrl !== match.url ? true : false;

    return {
        shouldRedirect,
        redirectUrl,
        metaIndex,
        productLvlPid,
    };
};

export const setIndex = (title, seoURLKeyword) => {
    //only index when we have seo title and seoURLKeyword
    return title && seoURLKeyword ? true : false;
};

export const getRedirectUrl = (currentPid, seoData, pdpUrlTemplate, locale) => {
    const redirectSEOPath = getCanonicalSEOPath(pdpUrlTemplate, locale, seoData.seoURLKeyword);
    const redirectNonSEOPath = getCanonicalSEOPath(pdpUrlTemplate, locale, `/${seoData.partNumber}`);
    let redirectUrl;
    if (currentPid === seoData.legacyId) {
        redirectUrl = redirectSEOPath ? redirectSEOPath : redirectNonSEOPath;
    } else if (currentPid === seoData.partNumber) {
        redirectUrl = redirectSEOPath ? redirectSEOPath : null;
    }
    return redirectUrl;
};

//try to match partnumber or legacy pid
export const matchPid = (currentPid, partNumber, legacyId) => {
    return partNumber === currentPid || legacyId === currentPid;
};

export const getCurrentMetaData = (productLvlPid, metaIndex, product, config) => {
    const canonicalUrl = `${config.head.seo.baseUrl}${product.seo.canonicalSEOPath}`;

    // todo TYS-1575 - Review when WCS fix SEO on colur variant correctly
    const ogDescription = product.seo.selectedProductColourSEO.metaDescription
        ? product.seo.selectedProductColourSEO.metaDescription
        : product.seo.productSEO.metaDescription;

    const description = product.seo.productSEO.metaDescription ? product.seo.productSEO.metaDescription : product.seo.selectedProductColourSEO.metaDescription;

    const title = product.seo.title ? product.seo.title : getPageTitle(product.seo, product.name, product.seo.selectedProductColourSEO.title, productLvlPid);
    const absoluteImageUrl = product.images?.[0]?.url.startsWith('//') ? `https:${product.images?.[0]?.url}` : product.images?.[0]?.url;

    return {
        titles: title,
        fullTitle: product.seo.selectedProductColourSEO.fullTitle && product.seo.selectedProductColourSEO.title,
        brandId: config.head.theme,
        description: description,
        keywords: productLvlPid ? product.seo.productSEO.metaKeyword : product.seo.selectedProductColourSEO.metaKeyword,
        og: {
            type: 'product',
            titles: title,
            image: absoluteImageUrl,
            description: ogDescription,
            url: canonicalUrl,
        },
        twitter: {
            card: 'summary_large_image',
            'image:alt': product.name,
        },
        robots: metaIndex ? 'index,follow' : 'noindex,nofollow',
        canonicalUrl,
        toPreload: getPreloadData(product, config),
    };
};

export const getPageTitle = (seo, productName, productColour, productLvlPid) => {
    const pageTitle = productLvlPid ? `${productName || seo?.productSEO?.title}` : `${productColour}`;
    return [pageTitle];
};

export const getPreloadData = (product, config) => {
    if (!product.images.length) {
        raygun.helpers.agent('send', {
            error: new Error(`getPreloadData error`),
            tags: [`error-getPreloadData`, `partNumber: ${product.partNumber}`, `product.images are not defined`],
        });
        return [];
    }

    const {
        pdp: { heroImageMobileSize },
    } = config.head;
    let toPreload = [];

    // this is the size loaded when visiting from iPhone X (trying to get more performance on mobile)
    const images = product.images?.[0].srcSet?.split(',');
    const heroImage = images?.find((image) => image.includes(`${heroImageMobileSize}w`));
    const heroImageHref = heroImage?.replace(`${heroImageMobileSize}w`, '').trim();

    const imageSrcSet = product.images?.[0].srcSet;
    imageSrcSet &&
        toPreload.push({
            href: heroImageHref,
            as: 'image',
            imagesrcset: imageSrcSet,
            imagesizes: '(min-width: 1024px) 560px, (min-width: 768px) 50vw, 100vw',
            fetchpriority: 'high',
        });

    return toPreload;
};
