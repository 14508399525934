import { pushData, productDetailsCustomParams } from '@ynap/live-chat';

/**
 * @param data analytics data
 * @param [lpTag] so components can cammunicate with this script through react-track dispatch system
 * @param [lpTag.additionalSections] so containers can pass aditional sections to lpTag
 */
const pushDataToLiveChat = (data, { additionalSections = [] } = {}) => {
    const shouldPushToLiveChat = data.event == 'trackPage';

    if (shouldPushToLiveChat) {
        const formattedData = data.listing ? productDetailsCustomParams({ ...data, product: data.listing.item }) : productDetailsCustomParams(data);

        if (formattedData?.pageSdes?.products?.length || formattedData?.sectionData?.length) {
            const country = data.page?.pageInfo?.geoRegion?.toUpperCase();
            const liveChatCountry = country === 'US' ? country : undefined;
            liveChatCountry && additionalSections.push(liveChatCountry);
            pushData({ ...formattedData, additionalSections: ['marketing', ...additionalSections] });
        }
    }
};

export default pushDataToLiveChat;
