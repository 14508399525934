import { plpActions } from './redux/product-listing-state';
import { pdpActions } from './redux/product-details-state';
import { dazActions } from './redux/designeraz-state';
import { StatusPage } from './components/StatusPage';
import filterQueries from './filterQueries';

const { getProductDetails } = pdpActions;
const { fetchListing, fetchSearchResults } = plpActions;
const { fetchDesigners } = dazActions;

import { plpPageAnalytics } from './analytics/plp';
import { pdpPageAnalytics } from './analytics/pdp';
import { dazPageAnalytics } from './analytics/daz';
import { errorPageAnalytics } from '@ynap/analytics';

import { DAZContainer, PDPContainer, PLPContainer } from './RoutesComponents'; // eslint-disable-line import/no-unresolved

const generateAppRoutes = (global, head) => {
    const { urlConfig } = global;
    const { enabled: menswearEnabled } = head.tonMenswear || {};
    const { itemsPerPage, facet = {}, contentOverride, useContentByUrl } = head.plp;

    const { itemsPerPage: searchItemsPerPage } = head.search;
    const { queries: queryAllowlist } = head.routesAllowlist;
    const { ignoreCategories = [] } = facet;

    const TONMenswearDAZ = urlConfig?.mensazdesigners
        ? {
              path: urlConfig?.mensazdesigners?.route,
              component: DAZContainer,
              exact: true,
              action: fetchDesigners('/mens', 'mensDAZ'),
              loadData: fetchDesigners('/mens', 'mensDAZ'),
              trackPage: dazPageAnalytics,
              key: 'mensDAZ',
          }
        : undefined;

    const TONMenswearSearch = urlConfig?.menssearch
        ? {
              path: urlConfig.menssearch.route,
              component: PLPContainer,
              exact: true,
              action: fetchSearchResults,
              pathParam: 'SEARCH_TERMS',
              loadData: loadListingData(fetchSearchResults, 'SEARCH_TERMS', searchItemsPerPage, queryAllowlist, null, null, 'mens'),
              filterCategoryByPath: false,
              trackPage: plpPageAnalytics,
              key: 'MENSSEARCH',
              fetchParams: (params) => (!params?.categoryId ? { category: '/mens' } : {}),
          }
        : undefined;

    const routes = [
        {
            path: urlConfig.saleazdesigners.route,
            component: DAZContainer,
            exact: true,
            action: fetchDesigners(),
            loadData: fetchDesigners(),
            trackPage: dazPageAnalytics,
            key: 'SALEDAZ',
        },
        {
            path: urlConfig.azdesigners.route,
            component: DAZContainer,
            exact: true,
            action: fetchDesigners(undefined, 'DAZ'),
            loadData: fetchDesigners(undefined, 'DAZ'),
            trackPage: dazPageAnalytics,
            key: 'DAZ',
        },
        {
            path: urlConfig.search.route,
            component: PLPContainer,
            exact: true,
            action: fetchSearchResults,
            pathParam: 'SEARCH_TERMS',
            loadData: loadListingData(fetchSearchResults, 'SEARCH_TERMS', searchItemsPerPage, queryAllowlist, null, null, menswearEnabled ? 'womens' : null),
            filterCategoryByPath: false,
            trackPage: plpPageAnalytics,
            key: 'SEARCH',
            fetchParams: menswearEnabled ? () => ({ excludeCategory: '/mens' }) : undefined,
        },
        {
            path: urlConfig.pdp.route,
            component: PDPContainer,
            exact: true,
            loadData: (props, request) => {
                const {
                    params: { PART_NUMBER },
                } = props;
                return getProductDetails(PART_NUMBER, request.originalUrl);
            },
            trackPage: pdpPageAnalytics,
            key: 'PDP',
        },
        ...ignoreCategories.map((seoPath) => blockListedRoute(urlConfig.plp.route, seoPath)),
        {
            path: urlConfig.plp.route,
            component: PLPContainer,
            exact: true,
            action: fetchListing,
            pathParam: 'SEO_KEYWORDS',
            loadData: loadListingData(fetchListing, 'SEO_KEYWORDS', itemsPerPage, queryAllowlist, contentOverride, useContentByUrl),
            trackPage: plpPageAnalytics,
            filterCategoryByPath: true,
            key: 'PLP',
        },
        {
            component: StatusPage,
            trackPage: errorPageAnalytics,
            key: 'NotFound',
        },
    ];

    TONMenswearDAZ && routes.unshift(TONMenswearDAZ);
    TONMenswearSearch && routes.unshift(TONMenswearSearch);

    return routes;
};

export const loadListingData = (action, pathParam, itemsPerPage, queryAllowlist, contentOverride, useContentByUrl, gender) => {
    return (props, request) => {
        const { query } = request;
        const { params } = props;
        const path = params[pathParam];
        const hostname = `${request.protocol}://${request.headers.host}`;
        const originalPath = useContentByUrl ? props.url : undefined;
        const defaultParams = {
            pageNumber: 1,
            pageSize: itemsPerPage || 60,
            attrs: 'true',
        };
        const genderParam = gender === 'mens' ? { category: '/mens' } : { excludeCategory: '/mens' };

        const AllowlistedQueries = filterQueries(queryAllowlist, query);
        const combinedParams = Object.assign(defaultParams, AllowlistedQueries, gender ? genderParam : {});
        return action(path, combinedParams, request.originalUrl, contentOverride, undefined, originalPath, hostname);
    };
};

const blockListedRoute = (basePath, seoPath) => ({
    path: `${basePath}${seoPath}`,
    strict: true,
    exact: true,
    component: StatusPage,
    trackPage: errorPageAnalytics,
    key: `BlockList${seoPath}`,
});

export { generateAppRoutes, filterQueries };
