export function getProductSizeChart(skus) {
    return skus.reduce(
        (acc, currentSku) => {
            const measurements = currentSku.II_MEASUREMENTS ? currentSku.II_MEASUREMENTS : currentSku?.WCS_GRP_MEASUREMENTS;
            const measurementType = currentSku.II_MEASUREMENTS ? 'iiMeasurements' : 'grpMeasurements';

            if (measurements) {
                measurements.values.map((value) => {
                    const valueCm = value.values[0]?.label;
                    const valueInches = value.values[1]?.label;
                    const label = currentSku.size.centralSizeLabel || currentSku.size.labelSize;
                    acc.measurementType = measurementType;
                    acc.sizeMeasurement.cm = valueCm && populateSizeChartData(acc.sizeMeasurement.cm, label, value.label, valueCm);
                    acc.sizeMeasurement.in = valueInches && populateSizeChartData(acc.sizeMeasurement.in, label, value.label, valueInches);
                });
            }

            if (currentSku.size) {
                currentSku.size.schemas.map((value) => {
                    const label = currentSku.size.centralSizeLabel || currentSku.size.labelSize;
                    acc.sizeConversion = populateSizeChartData(
                        acc.sizeConversion,
                        label,
                        value.name,
                        value.labels.length > 1
                            ? `${removeLabelFromValue(value.labels[0])}-${removeLabelFromValue(value.labels[value.labels.length - 1])}`
                            : removeLabelFromValue(value.labels[0]),
                    );
                });
            }

            if (currentSku.size) {
                const sizes = currentSku.size.schemas.map((schema) => {
                    const schemaSizes = schema.labels.map(removeLabelFromValue);
                    const size = schemaSizes.length > 1 ? `${schemaSizes[0]}-${schemaSizes[schemaSizes.length - 1]}` : schemaSizes[0];
                    const originalSizeLabel = schema.labels.length > 1 ? `${schema.labels[0]}-${schema.labels[schema.labels.length - 1]}` : schema.labels[0];

                    return {
                        schemaLabel: schema.name,
                        sizeLabel: `${schema.name} ${size}`,
                        size,
                        selected: schema.selected,
                        originalSizeLabel,
                    };
                });

                acc.sizeConversionBySku.push({
                    sizeLabel: currentSku.size.centralSizeLabel || currentSku.size.labelSize,
                    preferredSize: sizes.find((s) => s.selected)?.sizeLabel,
                    preferredSizeLabel: sizes.find((s) => s.selected)?.originalSizeLabel,
                    sizes,
                });
            }

            return acc;
        },
        {
            sizeMeasurement: {
                cm: null,
                in: null,
            },
            sizeConversion: null,
            sizeConversionBySku: [],
        },
    );
}

// Removes every character and returns the digit (can be float) except for XXS, XS, S, M, L, XL, XXL, XXXL, 4XL
const removeLabelFromValue = (value) => value?.match?.(/\b\d*XL\b|\d+(.\d+)?|\bXXS\b|\bXS\b|\bS\b|\bM\b|\bL\b|\bXXL\b|\bXXXL\b/gm)?.join?.('') || '';

export function populateSizeChartData(array, xLabel, yLabel, newValue) {
    if (!array) {
        array = [['']];
    }

    //Add Header label if doen't already exist
    if (xLabel && !array[0].includes(xLabel)) {
        array[0].push(xLabel);
        //add column to all other rows
        array.map((row) => {
            if (row.length < array[0].length) {
                row.push('');
            }
        });
    }

    //Add Row label if doen't already exist
    if (yLabel && array.findIndex((row) => row[0] === yLabel) < 0) {
        array.push([yLabel]);
        //Fill in the rest of the new row
        while (array[array.length - 1].length < array[0].length) {
            array[array.length - 1].push('');
        }
    }

    const columnIndex = array[0].indexOf(xLabel); //find correct column index (sku)
    const rowIndex = array.findIndex((row) => row[0] === yLabel); //find correct row index (y index)

    if (columnIndex > 0 && rowIndex > 0) {
        array[rowIndex][columnIndex] = newValue; //apply new value at that posiiton
    }

    return array;
}
