import { matchPath } from 'react-router';

export const registerWindowFunction = (window, country, head, routes, history) => {
    window.BL = {};
    window.BL.goToUrl = (url) => {
        if (!url) {
            return false;
        }
        let validCountries = head?.hijackSifuLinks?.countries;
        // if no valid countries specified but isHijackEnabled = true, enable for all countries
        let isCountryValid = validCountries?.includes?.(country.toLowerCase()) || !validCountries;
        let isHijackEnabled = head?.hijackSifuLinks?.enable;
        const domain = head.seo.baseUrl;
        const newUrl = url.replace(domain, '');

        const validRoute = routes.find(({ path }) => {
            const match = matchPath(newUrl, {
                path,
                exact: true,
                strict: false,
            });
            if (match) {
                return true;
            }
        });
        if (validRoute && isCountryValid && isHijackEnabled) {
            history.push(newUrl);
            return true;
        }
        return false;
    };
};
