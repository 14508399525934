import { pdpConstants } from '@ynap/product-details-state';
import { CHANGE_SELECTED_PART_NUMBER, PRODUCT_DETAILS_REQUEST, PRODUCT_DETAILS_SUCCESS, RESET_STATE } from '../constants';
import { LOCATION_CHANGE } from 'connected-react-router';

import { getSelectedProductVariantColourPartNumbers } from '../selectors';

const { CHANGE_SELECTED_SKU } = pdpConstants;

const initialState = {
    selectedPartNumber: null,
    colourPartNumbers: [],
    selectedSku: null,
    loading: false,
};

export default function appReducer(state = initialState, action = {}) {
    switch (action.type) {
        case CHANGE_SELECTED_PART_NUMBER:
            return {
                ...state,
                selectedPartNumber: action.partNumber,
                selectedSku: null,
            };

        case CHANGE_SELECTED_SKU:
            return {
                ...state,
                selectedSku: action.sku,
            };

        case PRODUCT_DETAILS_REQUEST: {
            return { ...state, loading: true };
        }

        case PRODUCT_DETAILS_SUCCESS: {
            const {
                body: { products },
            } = action;

            return {
                ...initialState,
                colourPartNumbers: (products.length && getSelectedProductVariantColourPartNumbers(products[0])) || [],
                loading: false,
            };
        }

        case LOCATION_CHANGE: {
            return { ...state, loading: true };
        }

        case RESET_STATE: {
            return { ...initialState };
        }

        default:
            return state;
    }
}
