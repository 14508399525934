import { combineReducers } from '@ynap/reducer-utils';

import { default as listingReducer } from './responseReducer';
import { default as contentReducer } from './contentReducer';
import { default as selectedSchemas } from './selectedSchemas';
import { default as founditContentReducer } from './founditContentReducer';

export { listingReducer, selectedSchemas, founditContentReducer };

export default combineReducers({ listing: listingReducer, selectedSchemas, content: contentReducer, founditContent: founditContentReducer });
