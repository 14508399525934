import { createInstance } from '@ynap/optimizely-utils';

const getOptimizely = (sdkKey, isMockOptimizely = false) => {
    const datafile = window.optimizelyStateBL?.datafile;
    return window.optimizelyCreateInstance && !isMockOptimizely
        ? window.optimizelyCreateInstance
        : sdkKey || datafile
          ? createInstance({
                sdkKey,
                datafile,
                datafileOptions: {
                    autoUpdate: true,
                    updateInterval: 600000, // 10 minutes in milliseconds
                },
                disableLogger: true,
            })
          : {};
};

export default getOptimizely;
