import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { SimpleErrorPage } from '@ynap/error-page';
import track from 'react-tracking';
import { trackPageView } from '../analytics';

@track()
class Status extends PureComponent {
    componentDidMount() {
        // TODO: Throw error for raygun.
        // eslint-disable-next-line no-console
        console.error({ code: this.props.code, message: 'Status Code error page' });
        trackPageView(this.props);
    }

    render() {
        const { code, children } = this.props;
        return (
            <Route
                render={({ staticContext }) => {
                    if (staticContext) {
                        staticContext.status = code;
                    }
                    return children;
                }}
            />
        );
    }
}

export const StatusPage = (props) => {
    const { code = 404, displayMessage, actionLabel, children, messages = {}, prevPath, ...rest } = props;
    const fallbackMessages = {
        displayMessage: messages.error ? messages.error.notFound() : '',
        actionLabel: messages.error ? messages.error.goBack() : '',
    };

    return (
        <Status code={code} message={displayMessage || fallbackMessages.displayMessage} {...rest}>
            {children || (
                <SimpleErrorPage
                    error={{
                        status: code,
                        message: displayMessage,
                    }}
                    displayMessage={displayMessage || fallbackMessages.displayMessage}
                    actionLabel={actionLabel || fallbackMessages.actionLabel}
                    action={() => window.history.back()}
                    prevPath={prevPath}
                />
            )}
        </Status>
    );
};
