import { SET_LOCALE, SET_MESSAGES, SET_PREV_PATH, SET_PREVIEW_DATE, SET_CLICKED_PID } from './constants';

export function setLocale(locale) {
    return {
        type: SET_LOCALE,
        country: locale.country,
        language: locale.language,
        currency: locale.currency,
        langId: locale.langId,
        region: locale.region,
        locale: locale.locale,
        dir: locale.dir,
        defaultSalesCatalog: locale.defaultSalesCatalog,
        returnWindow: locale.returnWindow,
    };
}

export function setClickedPidOnPLP(clickedPidOnPLP = null) {
    return {
        type: SET_CLICKED_PID,
        clickedPidOnPLP,
    };
}

export function setPreviewDate(date) {
    return {
        type: SET_PREVIEW_DATE,
        previewDate: date,
    };
}

export function setMessages(messages) {
    return {
        type: SET_MESSAGES,
        notificationMessages: messages.notifications,
    };
}

export function setPreviousPath(path) {
    return {
        type: SET_PREV_PATH,
        prevPath: path,
    };
}
