import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import App from 'app/containers/AppContainer';
import { generateAppRoutes } from 'app/Routes';
import configureStore from 'app/redux/store';
import configureMiddleware from 'app/redux/middleware';
import { ConnectedRouter } from 'connected-react-router';
import { raygun } from '@ynap/clientside-monitoring';
import { StylingProvider, createRenderer } from '@ynap/fela';
import { loadableReady } from '@loadable/component';
import { registerWindowFunction } from './registerWindowFunction';
import { createBrowserHistory } from 'history';
import getOptimizely from './getOptimizely';
// we need to initialise fetch with a store BEFORE middleware, so have to pass a ref and update as soon as the store is created
const storeRef = {};

const history = createBrowserHistory();
const middlewares = configureMiddleware({ storeRef, history });
const store = configureStore(window.state, middlewares, history);

storeRef.getState = store.getState;
storeRef.dispatch = store.dispatch;

const { config, appState } = store.getState();
const { head, global: globalConfig } = config;
const { country } = appState;

__webpack_public_path__ = head.assets.host + '/';

if (head.raygun && head.raygun.apiKey) {
    raygun.agent.init(head.raygun);
    raygun.helpers.routerTrackEvent(history);
}

const rootElement = document.getElementsByTagName('main')[0];

const routes = generateAppRoutes(globalConfig, head);

registerWindowFunction(window, country, head, routes, history);

const sdkKey = config?.head?.optimizely?.sdkKey;
const mockOptimizely = config?.head?.optimizely?.mockOptimizely;
const optimizely = getOptimizely(sdkKey, mockOptimizely);

const renderFunction = (messages) => {
    const renderer = createRenderer({ brand: head.theme });

    if (window.Cypress) {
        window.store = store;
    }

    loadableReady(() => {
        hydrate(
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <StylingProvider renderer={renderer} brand={head.theme}>
                        <App messages={messages} routes={routes} optimizely={optimizely} />
                    </StylingProvider>
                </ConnectedRouter>
            </Provider>,
            rootElement,
        );
    });
};

export default renderFunction;
