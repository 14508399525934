import { SET_ERROR, RESET_STATE } from './constants';

export function setError(error) {
    return {
        type: SET_ERROR,
        value: {
            error: error.error,
            info: error.info,
        },
    };
}

//reset plp & pdp to initial state
export function resetState() {
    return {
        type: RESET_STATE,
    };
}
