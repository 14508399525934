import { DESIGNERS_CONTENT_REQUEST, DESIGNERS_CONTENT_SUCCESS, DESIGNERS_CONTENT_FAILURE } from '../constants';

const initialState = {
    response: null,
    loading: false,
    error: false,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case DESIGNERS_CONTENT_REQUEST:
            return {
                ...state,
                response: null,
                loading: true,
                error: false,
            };

        case DESIGNERS_CONTENT_SUCCESS:
            return {
                ...state,
                response: {
                    body: action.body,
                    headers: action.headers,
                    status: action.status,
                    path: action.uid,
                },
                error: false,
                loading: false,
            };

        case DESIGNERS_CONTENT_FAILURE:
            return {
                ...state,
                response: {
                    body: action.body,
                    headers: action.headers,
                    status: action.status,
                    path: action.uid,
                },
                error: true,
                loading: false,
            };

        default:
            return state;
    }
}
