import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import { ReduxLoadingBar } from '@ynap/loading-bar';
import { dispatch, Provider as AnalyticsProvider, errorPageAnalytics } from '@ynap/analytics';
import { RoutesAllowlistProvider } from '@ynap/router';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import { productActions } from '../../redux/product-state';
import { POP } from '../../redux/history';
import { StatusPage } from '../../components/StatusPage';
import { getCookie } from '@ynap/redux-cookies-middleware/src/cookieApi';
import { raygun } from '@ynap/clientside-monitoring';
import ImmutablesProvider from '@ynap/immutables-provider';
import pushDataToLiveChat from '../../utils/live-chat';
import { OptimizelyProvider } from '@ynap/optimizely-utils';
import { authActions } from '@ynap/auth-state';

const { setError, resetState } = productActions.error;
const { setPreviousPath, setClickedPidOnPLP, setPreviousLocation } = productActions.appState;
const { setUberToken } = authActions;

@track(
    {},
    {
        dispatch: /* istanbul ignore next */ ({ lpTag, ...gtmData }) => {
            dispatch(gtmData);
            pushDataToLiveChat(gtmData, lpTag);
        },
    },
)
export class AppContainer extends React.Component {
    static contextTypes = {
        router: PropTypes.shape({
            history: PropTypes.object.isRequired,
            route: PropTypes.object.isRequired,
            staticContext: PropTypes.object,
        }),
    };
    static propTypes = {
        router: PropTypes.shape({
            history: PropTypes.object.isRequired,
            route: PropTypes.object.isRequired,
            staticContext: PropTypes.object,
        }),
        config: PropTypes.shape({
            routesAllowlist: PropTypes.object.isRequired,
        }).isRequired,
        replace: PropTypes.bool.isRequired,
        messages: PropTypes.object.isRequired,
        routes: PropTypes.array.isRequired,
        location: PropTypes.object.isRequired,
    };
    constructor(props) {
        super(props);
        const { routesAllowlist = {} } = props.config.head;
        const { enabled = false, base = '', paths = [] } = routesAllowlist;
        this.routesAllowlist = enabled && paths.map((path) => base + path);
        this.externalRoutesAllowlist = this.routesAllowlist || ['/:locale/shop/*', '/:locale/mens/*'];

        props.history.listen((location, action) => {
            //always scroll to top of carousel when a non-loadmore link is clicked, like filter and pdp link
            if (action !== POP && !location?.state?.fetchMore) {
                const scrollPoint = document.getElementById('scroll-point');
                if (scrollPoint && location?.state?.pidOnlySkeleton) {
                    // pidOnlySkeleton tells us we are on PLP -> PLP Navigation
                    const topPos = scrollPoint?.offsetTop;
                    if (window.scrollY > topPos) {
                        window.scroll(0, topPos);
                    }
                } else {
                    window.scroll(0, 0);
                }
            }
        });

        this.state = {
            analytics: {},
            ssr: true,
            geoIP: '',
            routeKey: '',
        };
    }

    componentDidCatch(error, info) {
        this.props.setError({
            error: error.toString(),
            info,
        });
    }

    //when there was an error and the route changes, reset state and let the corresponding container try to rerender and fetch data again
    componentDidUpdate(prevProps, prevState) {
        const {
            location: { pathname, search },
        } = this.props;
        const { pathname: prevPathname, search: prevSearch } = prevProps.location;

        if (prevPathname !== pathname || prevSearch !== search) {
            this.setState({ ssr: false });
            this.props.setPreviousPath(prevPathname + prevSearch);
            if (prevProps.error.error) {
                this.props.resetState();
            }
        }
        if (this.state.routeKey !== prevState.routeKey) {
            raygun.helpers.agent('withTags', [this.state.routeKey]);
        }
    }

    componentDidMount() {
        const geoIP = getCookie('geoIP');
        geoIP &&
            this.setState({
                geoIP,
            });

        document.addEventListener('userchange', (event) => {
            const uberToken = event.detail && event.detail.uberToken;
            if (uberToken) {
                this.props.setUberToken(uberToken);
            }
        });
    }

    updateAnalytics = (analytics, routeKey) => {
        this.setState({ analytics, routeKey });
    };

    render() {
        const { messages, routes, error, appState, location, config, setClickedPidOnPLP, optimizely } = this.props;
        const { geoIP, ssr, analytics } = this.state;

        return error.error ? (
            <StatusPage
                code={500}
                displayMessage={messages.error.unknownError()}
                actionLabel={messages.error.goBack()}
                appState={appState}
                location={location}
                config={config}
                error={error.error}
                trackPage={errorPageAnalytics}
                analytics={this.state.analytics}
            />
        ) : (
            <RoutesAllowlistProvider value={this.routesAllowlist}>
                <div className="app">
                    <ReduxLoadingBar />
                    <ImmutablesProvider value={{ messages, config, appState: { ...appState, geoIP } }}>
                        <Switch>
                            {routes.map((route) => {
                                const { component, path, exact, key, action, pathParam, filterCategoryByPath, trackPage, fetchParams } = route;
                                const Component = component;
                                return (
                                    <Route
                                        path={path}
                                        exact={exact}
                                        key={key}
                                        render={({ staticContext, ...routeProps }) => {
                                            if (staticContext) staticContext.pageKey = key;
                                            return (
                                                <OptimizelyProvider optimizely={optimizely} isServerSide={ssr} pageGroup={key.toLowerCase()}>
                                                    <AnalyticsProvider value={{ ...analytics }}>
                                                        <Component
                                                            messages={messages}
                                                            action={action}
                                                            pathParam={pathParam}
                                                            filterCategoryByPath={filterCategoryByPath}
                                                            routeKey={key}
                                                            trackPage={trackPage}
                                                            appState={appState}
                                                            location={location}
                                                            config={config}
                                                            analytics={analytics}
                                                            updateAnalytics={this.updateAnalytics}
                                                            setClickedPidOnPLP={setClickedPidOnPLP}
                                                            ssr={ssr}
                                                            geoIP={geoIP}
                                                            fetchParams={fetchParams}
                                                            {...routeProps}
                                                        />
                                                    </AnalyticsProvider>
                                                </OptimizelyProvider>
                                            );
                                        }}
                                    />
                                );
                            })}
                        </Switch>
                    </ImmutablesProvider>
                </div>
            </RoutesAllowlistProvider>
        );
    }
}

function mapStateToProps(state) {
    return {
        appState: state.appState,
        config: state.config,
        error: state.error,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setError,
        resetState,
        setPreviousPath,
        setPreviousLocation,
        setClickedPidOnPLP,
        setUberToken: (uberToken) => dispatch(setUberToken(uberToken)),
    };
}

export default hot(module)(withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContainer)));
