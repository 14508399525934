export function getConfig(state) {
    const { config } = state;
    return config;
}

export function getLocale(state) {
    const { locale } = state;
    if (!locale) {
        // TODO Align apps locale storage.
        return state.appState;
    }
    return locale;
}

export function getMessages(state) {
    return state.appState.messages;
}

export function getUberToken(state) {
    const { uberToken } = state;
    if (!uberToken && state.auth) {
        // TODO Align apps auth storage.
        return state.auth.uberToken;
    }
    return uberToken;
}

export function getCommerceConfig(state) {
    const uberToken = getUberToken(state);
    const { global = {} } = getConfig(state);
    const { commerce = {} } = global.services;
    const { brandId } = global.application;
    const { country, language, locale } = getLocale(state);
    return { uberToken, country, language, locale, brandId, ...commerce };
}
