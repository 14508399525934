import { SET_LOCALE, SET_MESSAGES, SET_PREV_PATH, SET_PREVIEW_DATE, SET_CLICKED_PID } from './constants';

const initialState = {
    language: null,
    country: null,
    locale: null,
    currencyCode: null,
    langId: null,
    region: null,
    langISO: null,
    messages: null,
    prevPath: null,
    previewDate: null,
    clickedPidOnPLP: null,
    defaultSalesCatalog: null,
    returnWindow: null,
};
// does the below even make sense, default arguments need to be declared last
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_LOCALE:
            return {
                ...state,
                language: action.language,
                country: action.country,
                locale: `${action.language.toLowerCase()}-${action.country.toLowerCase()}`,
                currencyCode: action.currency,
                langId: action.langId,
                region: action.region,
                langISO: action.locale,
                dir: action.dir,
                defaultSalesCatalog: action.defaultSalesCatalog,
                returnWindow: action.returnWindow,
            };

        case SET_CLICKED_PID:
            return {
                ...state,
                clickedPidOnPLP: action.clickedPidOnPLP,
            };

        case SET_PREVIEW_DATE:
            // Check to see the date passed is valie before
            // before creating new date, else return null
            return {
                ...state,
                previewDate: !isNaN(Date.parse(action.previewDate)) ? new Date(action.previewDate).toISOString() : null,
            };

        case SET_MESSAGES:
            return {
                ...state,
                messages: {
                    notifications: action.notificationMessages,
                },
            };

        case SET_PREV_PATH:
            return {
                ...state,
                prevPath: action.prevPath,
            };

        default:
            return state;
    }
}
