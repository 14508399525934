import { UPDATE_SELECTED_SCHEMA } from '../constants';

const initialState = {};

export default function selectedSchemas(state = initialState, action = {}) {
    switch (action.type) {
        case UPDATE_SELECTED_SCHEMA: {
            return { ...state, [action.payload.schemaType]: action.payload.value };
        }

        default:
            return state;
    }
}
