import { raygun } from '@ynap/clientside-monitoring';

export default function getOrderBy(state) {
    const { response, error } = state.plp.listing;

    if (response && response.body && !response.body.orderBy) {
        const tagMessage = `response.body.orderBy is null/not defined`;
        raygun.helpers.agent('send', {
            error: new Error(`getOrderBy error`),
            tags: [`error-getOrderBy`, `${tagMessage}`],
            customData: error ? error : '',
        });
    }
    if (!response || !response.body.orderBy || error) return null;

    return response.body.orderBy.map((option) => ({ value: option.key, ...option }));
}
