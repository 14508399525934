import { pdpConstants } from '@ynap/product-details-state';

const { CHANGE_SELECTED_SKU } = pdpConstants;

export default (sku, sizeLabel) => {
    return (dispatch) => {
        return dispatch({
            type: CHANGE_SELECTED_SKU,
            sku,
            sizeLabel,
        });
    };
};
