import { Catalogue } from '@ynap/api-utils';
import apiAction from '@ynap/api-fetch-action';

export default (partNumber, uid) => {
    return (dispatch, getState) => {
        const state = getState();
        const { country, language, langISO } = state.appState;
        const { services, application } = state.config.global;
        const { baseUrl, clientId } = services.commerce;
        const configState = state.config;

        const options = {
            locale: langISO,
        };

        const config = { baseUrl, brandId: application.brandId, country, language, partNumber, clientId, configState, options };

        const request = Catalogue.getDetailsByPartNumber(config);

        return dispatch(apiAction(request, 'PRODUCT_DETAILS', uid, { partNumber }));
    };
};
