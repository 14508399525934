import { constructUrl } from '@ynap/url-configuration';

export default function getDesignerLinkBySeoPath(plpUrlTemplate, locale, seo) {
    const seoPath = seo && seo.seoURLKeyword;

    return plpUrlTemplate && locale
        ? constructUrl(plpUrlTemplate, {
              LOCALE: locale,
              SEO_URLKEYWORD: seoPath,
          })
        : undefined;
}
