import React from 'react';
import loadable from '@loadable/component';
import { timeout } from '@ynap/promise-utils';

import { DAZSkeleton } from './components/DAZSkeleton';
import { PDPSkeleton } from './components/PDPSkeleton';
import { PLPSkeleton } from './components/PLPSkeleton';

const DAZContainer = loadable(
    () => timeout(import(/* webpackInclude: /*(\.ton)?\.js/ */ /* webpackChunkName: "DAZContainerTON" */ './containers/DAZContainer'), 10000),
    {
        fallback: <DAZSkeleton />,
    },
);

const PDPContainer = loadable(
    () => timeout(import(/* webpackInclude: /*(\.ton)?\.js/ */ /* webpackChunkName: "PDPContainerTON" */ './containers/PDPContainer'), 10000),
    {
        fallback: <PDPSkeleton />,
    },
);

const PLPContainer = loadable(
    () => timeout(import(/* webpackInclude: /*(\.ton)?\.js/ */ /* webpackChunkName: "PLPContainerTON" */ './containers/PLPContainer'), 10000),
    {
        fallback: <PLPSkeleton />,
    },
);

export { DAZContainer, PDPContainer, PLPContainer };
