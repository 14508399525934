import { getPageInfo, safeBuildEventObj } from '@ynap/analytics';

const _dazPageAnalytics = async ({ appState, config, location, isSalePage }) => {
    const { language, country, prevPath } = appState;
    const pageName = isSalePage ? 'Sale Designer A-Z' : 'Designer A-Z';

    return {
        event: 'trackPage',
        page: {
            pageInfo: getPageInfo({ location, language, country, deviceType: 'website', pageName, prevUrl: prevPath }),
            category: getCategory(),
            attributes: getAttributes({ appState, config }),
        },
    };
};

export const getCategory = () => {
    return {
        pageType: 'landing page',
        primaryCategory: 'ecommerce',
        subCategory1: 'designer list',
    };
};

export const getAttributes = ({ appState, config }) => {
    return {
        currency: appState.currencyCode.toLowerCase(),
        businessName: config.head.theme.toUpperCase(),
        featuresList: [],
    };
};

export const dazPageAnalytics = (args) => safeBuildEventObj(_dazPageAnalytics, args);
