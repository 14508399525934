import { PRODUCT_LISTING_FOUNDIT_FAILURE, PRODUCT_LISTING_FOUNDIT_REQUEST, PRODUCT_LISTING_FOUNDIT_SUCCESS } from '../constants';

interface FounditContentResponse {
    ImageLinksEnabled?: boolean;
    Url?: string;
    Links?: {
        [key: string]: {
            AnchorText: string;
            DestinationUrl: string;
            ImageUrl: string;
            Order: number;
        }[];
    };
}

export interface FounditContentState {
    response:
        | {
              body: FounditContentResponse | null | undefined;
          }
        | null
        | undefined;
    loading: boolean;
    error: boolean;
}

interface FounditContentActionType {
    type?: typeof PRODUCT_LISTING_FOUNDIT_REQUEST | typeof PRODUCT_LISTING_FOUNDIT_FAILURE | typeof PRODUCT_LISTING_FOUNDIT_SUCCESS;
    body?: FounditContentResponse;
    headers?: object;
    status?: number;
}

const initialState: FounditContentState = {
    response: null,
    loading: false,
    error: false,
};

export default function reducer(state = initialState, action: FounditContentActionType = {}) {
    switch (action.type) {
        case PRODUCT_LISTING_FOUNDIT_REQUEST:
            return {
                ...state,
                response: null,
                loading: true,
                error: false,
            };

        case PRODUCT_LISTING_FOUNDIT_SUCCESS:
            return {
                ...state,
                response: {
                    body: action.body,
                    headers: action.headers,
                    status: action.status,
                },
                error: false,
                loading: false,
            };

        case PRODUCT_LISTING_FOUNDIT_FAILURE:
            return {
                ...state,
                response: {
                    body: action.body,
                    headers: action.headers,
                    status: action.status,
                },
                error: true,
                loading: false,
            };

        default:
            return state;
    }
}
