/**
 * Selects the ad and the saleBanner picture, if any from the state passed
 * @param {Object} state
 * @param {Boolean} isSalePage
 * @returns {Object} { loading: boolean, ad: Object, saleBanner: Object }
 */
export default function getContent(state, isSalePage = false) {
    const { response, error, loading } = state.daz.content;
    if (response === null || error) {
        return null;
    }

    const bannerToShow = response?.body?.above?.filter((banner) => banner.layoutVariant === `yos-daz-${isSalePage ? 'sale-' : ''}banner`)?.[0];

    // Default to first banner if no layoutVariant matches (eg. for MRP)
    const { picturesAndMedia = [], ynapParameter } = bannerToShow || response?.body?.above?.[0] || {};

    return {
        loading,
        ad: response?.body?.main?.[0],
        bannerLink: ynapParameter,
        banners: picturesAndMedia,
    };
}
