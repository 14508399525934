import { raygun } from '@ynap/clientside-monitoring';
import { formatNumber } from '@ynap/intl-formatters';

function checkStructureResponse(response) {
    let options = [],
        tagMessage = '';
    if (response?.body) {
        const data = response.body;
        const { pageNumber, pageSize, totalPages, recordSetCount, recordSetTotal, recordSetStartNumber } = data;
        pageNumber === undefined && options.push('pageNumber');
        pageSize === undefined && options.push('pageSize');
        totalPages === undefined && options.push('totalPages');
        recordSetCount === undefined && options.push('recordSetCount');
        recordSetTotal === undefined && options.push('recordSetTotal');
        recordSetStartNumber === undefined && options.push('recordSetStartNumber');
    } else {
        tagMessage = 'response.body is null/not defined';
    }

    if (tagMessage || options.length) {
        raygun.helpers.agent('send', {
            error: new Error(`getPaginationData error`),
            tags: [`error-getPaginationData`, `${options.length ? options.join(', ') + ' is null/not defined' : ''}${tagMessage ? ' ' + tagMessage : ''}`],
        });
    }
}
export default (state) => {
    const { response, error } = state.plp.listing;

    checkStructureResponse(response);

    if (!response || error) return null;
    const data = response.body;
    const { pageNumber, pageSize, totalPages, recordSetCount, recordSetTotal, recordSetStartNumber } = data;

    // for display purposes we don't want to have 0 pages, we'll always at least be on page 1 of 1
    let totalPagesNumber = parseInt(totalPages);
    if (totalPagesNumber === 0) {
        totalPagesNumber = 1;
    }

    return {
        pageNumber: parseInt(pageNumber),
        pageSize: parseInt(pageSize),
        totalPages: totalPagesNumber,
        recordSetCount: parseInt(recordSetCount),
        recordSetTotal: parseInt(recordSetTotal),
        recordSetStartNumber: parseInt(recordSetStartNumber),
        results: formatNumber(recordSetTotal, state.appState?.locale),
    };
};
