import { constructUrl } from '@ynap/url-configuration';
const getCanonicalUrl = (config, locale) =>
    `${config.head.seo.baseUrl}${constructUrl(config.global.urlConfig.azdesigners.construct, {
        LOCALE: locale,
    })}`;

const getMetaData = (config, locale, messages) => {
    return {
        robots: 'index,follow',
        brandId: config.head.theme,
        canonicalUrl: getCanonicalUrl(config, locale),
        titles: [messages.seo.azdesigners.title()],
        description: messages.seo.azdesigners.description(),
        keywords: messages.seo.azdesigners.keywords(),
        //TODO: update og and twitter once we integrate coremedia content
    };
};

export default getMetaData;
