import React from 'react';
import { createComponent } from '@ycos/fela';
import Skeleton from '@ynap/skeleton';
import { Gutter } from './shared';

const Header = createComponent('Header', ({ theme }) => ({
    padding: `${5 * theme.spacingMultiplier}px 0`,
    marginLeft: `auto`,
    marginRight: `auto`,
    marginTop: `${2 * theme.spacingMultiplier}px`,
    width: '50%',
    'screen-medium': {
        width: '25%',
    },
}));

const Wrapper = createComponent('Wrapper', ({ theme }) => ({
    display: 'block',
    marginLeft: `${2 * theme.spacingMultiplier}px`,
    marginRight: `${2 * theme.spacingMultiplier}px`,
    'screen-medium': {
        marginLeft: `${4 * theme.spacingMultiplier}px`,
        marginRight: `${4 * theme.spacingMultiplier}px`,
    },
}));

const AZMenu = createComponent(
    'AZMenu',
    ({ theme }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        height: `${5 * theme.spacingMultiplier}px`,
        marginLeft: `0 ${2 * theme.spacingMultiplier}px`,
        borderBottom: `1px ${theme.skeleton.color} solid`,
        paddingTop: `${2 * theme.spacingMultiplier}px`,
        paddingLeft: 0,
        overflow: 'hidden',
        'screen-medium': {
            margin: `0 ${4 * theme.spacingMultiplier}px`,
        },
    }),
    'ul',
);

const AZ = createComponent(
    'AZ',
    () => ({
        minWidth: '20px',
        listStyle: 'none',
    }),
    'li',
);

const AZTitle = createComponent('AZTitle', ({ theme }) => ({
    width: `${7 * theme.spacingMultiplier}px`,
    padding: `${5 * theme.spacingMultiplier}px 0 ${4 * theme.spacingMultiplier}px`,
}));

const AZInitial = createComponent('AZInitial', ({ theme }) => ({
    minWidth: '100%',
    marginRight: `${2 * theme.spacingMultiplier}px`,
    'screen-medium': {
        marginRight: `${4 * theme.spacingMultiplier}px`,
    },
}));

const DesignerList = createComponent(
    'DesignerList',
    () => ({
        flex: '0 0 100%',
        display: 'flex',
        flexWrap: 'wrap',
        margin: 0,
        padding: 0,
    }),
    'ul',
);

const Designer = createComponent(
    'Designer',
    ({ theme }) => ({
        display: 'block',
        width: '100%',
        marginBottom: `${2 * theme.spacingMultiplier}px`,
        'screen-medium': {
            width: '31%',
        },
        'screen-large': {
            width: '23%',
        },
    }),
    'li',
    ['key'],
);

export const DAZSkeleton = () => (
    <Gutter>
        <Header>
            <Skeleton align="left" />
        </Header>
        <AZMenu>
            {new Array(25).fill().map((v, i) => (
                <AZ key={`AZ${i}`}>
                    <Skeleton align="left" />
                </AZ>
            ))}
        </AZMenu>
        {new Array(5).fill().map((v, i) => (
            <Wrapper key={`Wrapper${i}`}>
                <AZInitial>
                    <AZTitle>
                        <Skeleton />
                    </AZTitle>
                </AZInitial>
                <DesignerList>
                    {new Array(8).fill().map((v, i) => (
                        <Designer key={`Product${i}`}>
                            <Skeleton align="left" />
                        </Designer>
                    ))}
                </DesignerList>
            </Wrapper>
        ))}
    </Gutter>
);

export default DAZSkeleton;
