import { productSelectors } from '@ynap/product-state';
import { createSelector } from 'reselect';
import { default as getSelectedCategory } from './getSelectedCategory';

const { getMappedProducts } = productSelectors;

export const getProductsInternal = (error, visibleProducts, config, locale, pageType, messages, loadingRequest, user) => {
    if (error || !visibleProducts) {
        return null;
    }

    const badgeBlocklistString = config.head.plp?.badgeBlocklist;
    const badgeBlocklistArray = badgeBlocklistString?.split(',');
    const appProductConfig = {
        pdpUrlTemplate: config.global.urlConfig.pdp.construct,
        badgeBlocklist: badgeBlocklistArray,
        locale,
        availableImageSizes: config.head.plpAvailableImageSizes,
        imageQuality: config.head.imageQualityPercentage,
        saleBadges: config.global.application?.saleBadges,
        imagesphereAspectRatio: config.head.aspectRatio?.imagesphere,
        plpImageSrcSet: config.head.plpImageSrcSet,
    };
    const useIdentifier = config.head.useIdentifier;

    return visibleProducts.map((pl) => {
        return {
            key: pl.key,
            loading: loadingRequest || pl.loading,
            products: getMappedProducts(pl.products, appProductConfig, pageType, messages, useIdentifier, config, user),
        };
    });
};

export default createSelector(
    [
        (state) => state.plp?.listing?.error,
        (state) => state.plp?.listing?.visibleProducts,
        (state) => state.config,
        (state) => state.appState?.locale,
        (state, props) => getSelectedCategory(state, props?.routeKey)?.pageType,
        (state, props) => props.messages,
        (state, props, loadingState) => loadingState?.loadingRequest,
        (state) => state.userState,
    ],
    getProductsInternal,
);
