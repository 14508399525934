import getDesignerLinkBySeoPath from './getDesignerLinkBySeoPath';

/**
 * Receives data for a single designer object, returns a sanitised version
 * @param {string} id
 * @param {object} designer
 * @param {string} locale (eg. "en-gb")
 * @param {string} urlTemplate (eg. "/{{LOCALE}}/mens{{SEO_URLKEYWORD}}")
 * @param {bool} isSale
 * @returns {object} mapped designer
 */
export default function mapDesigner(id, designer, locale, urlTemplate, isSale = false) {
    const { badge, label, identifier, productCounts = {}, seo = {}, saleSeo, attributes } = designer;

    const brandId = attributes.find((attr) => attr.identifier === 'Brand')?.values[0]?.identifier;

    const getSaleChanges = (isSale) =>
        isSale
            ? {
                  badge: null,
                  productCount: designer.productCounts.onSale,
                  href: getDesignerLinkBySeoPath(urlTemplate, locale, saleSeo),
              }
            : {
                  badge,
                  productCount: designer.productCounts.fullPrice,
                  href: getDesignerLinkBySeoPath(urlTemplate, locale, seo),
              };

    return {
        designerId: id,
        designerLabel: label,
        designerName: label,
        brandId,
        identifier,
        productCounts,
        ...getSaleChanges(isSale),
    };
}
