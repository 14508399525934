import React from 'react';
import { createComponent } from '@ycos/fela';
import Skeleton from '@ynap/skeleton';
import { Gutter, ProductImage } from './shared';
import { withConfig } from '@ynap/immutables-provider';

const Header = createComponent('Header', ({ theme }) => ({
    marginTop: `${4 * theme.spacingMultiplier}px`,
    marginBottom: `${2 * theme.spacingMultiplier}px`,
    marginLeft: `${4 * theme.spacingMultiplier}px`,
    marginRight: `${4 * theme.spacingMultiplier}px`,
    width: '50%',
    'screen-medium': {
        marginBottom: `${4 * theme.spacingMultiplier}px`,
        marginLeft: `${4 * theme.spacingMultiplier}px`,
        marginRight: `${4 * theme.spacingMultiplier}px`,
        width: '25%',
    },
}));

const Wrapper = createComponent('Wrapper', ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: `${2 * theme.spacingMultiplier}px`,
    marginRight: `${2 * theme.spacingMultiplier}px`,
    'screen-medium': {
        marginLeft: `${4 * theme.spacingMultiplier}px`,
        marginRight: `${4 * theme.spacingMultiplier}px`,
    },
}));

const ResultsAndSortBy = createComponent('ResultsAndSortBy', ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: `${2 * theme.spacingMultiplier}px`,
    marginBottom: `${1 * theme.spacingMultiplier}px`,
    marginLeft: `${2 * theme.spacingMultiplier}px`,
    marginRight: `${2 * theme.spacingMultiplier}px`,
    paddingTop: `${2 * theme.spacingMultiplier}px`,
    borderTop: `1px ${theme.skeleton.color} solid`,
    'screen-medium': {
        marginTop: `${4 * theme.spacingMultiplier}px`,
        marginBottom: `${3 * theme.spacingMultiplier}px`,
        marginLeft: `${4 * theme.spacingMultiplier}px`,
        marginRight: `${4 * theme.spacingMultiplier}px`,
        paddingTop: `${4 * theme.spacingMultiplier}px`,
    },
}));

const Results = createComponent('Results', () => ({
    width: '15%',
}));

const SortBy = createComponent('SortBy', () => ({
    width: '15%',
}));

const Filters = createComponent(
    'Filters',
    ({ theme }) => ({
        display: 'none',
        margin: 0,
        padding: 0,
        flex: '0 0 25%',
        borderTop: `1px ${theme.skeleton.color} solid`,
        'screen-large': {
            display: 'flex',
            flexFlow: 'column nowrap',
        },
    }),
    'ul',
);

const Filter = createComponent(
    'Filter',
    ({ theme }) => ({
        listStyle: 'none',
        marginTop: `${2 * theme.spacingMultiplier}px`,
        paddingBottom: `${1 * theme.spacingMultiplier}px`,
        borderBottom: `1px ${theme.skeleton.color} solid`,
    }),
    'li',
    ['key'],
);

const ProductGrid = createComponent(
    'ProductGrid',
    ({ theme }) => ({
        flex: '0 0 100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        margin: 0,
        padding: 0,
        'screen-large': {
            flex: '0 0 73%',
            paddingLeft: `${4 * theme.spacingMultiplier}px`,
        },
    }),
    'ul',
);

const ProductItem = createComponent(
    'ProductItem',
    ({ theme }) => ({
        display: 'block',
        width: '48%',
        marginBottom: `${4 * theme.spacingMultiplier}px`,
        'screen-medium': {
            width: '23%',
        },
        'screen-large': {
            width: '31%',
        },
    }),
    'li',
    ['key'],
);

const SkeletonWrapper = (props) => {
    const { productImage } = props.config;
    return (
        <Gutter>
            <Header>
                <Skeleton align="left" />
            </Header>
            <ResultsAndSortBy>
                <Results>
                    <Skeleton align="left" />
                </Results>
                <SortBy>
                    <Skeleton />
                </SortBy>
            </ResultsAndSortBy>
            <Wrapper>
                <Filters>
                    {new Array(4).fill().map((v, i) => (
                        <Filter key={`Filter${i}`}>
                            <Skeleton count={2} align="left" />
                        </Filter>
                    ))}
                </Filters>
                <ProductGrid>
                    {new Array(12).fill().map((v, i) => (
                        <ProductItem key={`Product${i}`}>
                            <ProductImage imageAR={productImage} />
                            <Skeleton count={3} align="left" />
                        </ProductItem>
                    ))}
                </ProductGrid>
            </Wrapper>
        </Gutter>
    );
};

export const PLPSkeleton = withConfig((config) => config.head.aspectRatio)(SkeletonWrapper);
export default PLPSkeleton;
