import { push } from 'connected-react-router';
import { notificationActions } from '@ynap/notification-state';
import { isUserAllowed } from '@ynap/user-state';
import { constructUrl } from '@ynap/url-configuration';
import { AUTHENTICATED } from '../constants';

export default function checkViewPagePermissions(
    user,
    pageType,
    isPageVisible,
    restrictedToSegments = [],
    redirectUrl,
    messages,
    visibilityDates,
    isEipPreviewNoProduct,
) {
    return (dispatch, getState) => {
        const {
            appState: { locale },
        } = getState();

        const { PAGE_NOT_AVAILABLE, PAGE_SOON, PAGE_CLOSED } = messages.notifications;
        const currentDate = new Date();
        const startD = new Date(visibilityDates?.startDate);
        const endD = new Date(visibilityDates?.endDate);
        const toEarly = currentDate < startD;
        const toLate = currentDate > endD;

        if (!isUserAllowed(restrictedToSegments, user)) {
            dispatch(
                notificationActions.setNotification({ name: 'action', type: 'info', message: PAGE_NOT_AVAILABLE.message(), title: PAGE_NOT_AVAILABLE.title() }),
            );
            dispatch(push(constructUrl(redirectUrl, { LOCALE: locale })));
            return { authenticated: false };
        } else if ((!isPageVisible && toEarly) || isEipPreviewNoProduct) {
            dispatch(notificationActions.setNotification({ name: 'action', type: 'info', message: PAGE_SOON.message({ pageType }), title: PAGE_SOON.title() }));
            dispatch(push(constructUrl(redirectUrl, { LOCALE: locale })));
            return { authenticated: false };
        } else if (!isPageVisible && toLate) {
            dispatch(
                notificationActions.setNotification({ name: 'action', type: 'info', message: PAGE_CLOSED.message({ pageType }), title: PAGE_CLOSED.title() }),
            );
            dispatch(push(constructUrl(redirectUrl, { LOCALE: locale })));
            return { authenticated: false };
        } else {
            dispatch({
                type: AUTHENTICATED,
            });
            return { authenticated: true };
        }
    };
}
