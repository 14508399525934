import { SET_CONFIG } from './constants';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_CONFIG:
            return action.value;

        default:
            return state;
    }
}
