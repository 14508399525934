import { FETCH_DESIGNERS_REQUEST, FETCH_DESIGNERS_SUCCESS, FETCH_DESIGNERS_FAILURE } from '../constants';

const initialState = {
    response: null,
    loading: false,
    error: false,
};

export default function (state = initialState, action = {}) {
    const { type } = action;

    switch (type) {
        case FETCH_DESIGNERS_REQUEST:
            return {
                ...initialState,
                loading: true,
            };

        case FETCH_DESIGNERS_SUCCESS: {
            const designersWithSale = action.body.designers?.filter((designer) => designer.productCounts.onSale > 0);
            return {
                ...state,
                response: {
                    designerFacets: action.body.designerFacets,
                    designers: action.body.designers,
                    designersWithSale: designersWithSale,
                    headers: action.headers,
                    path: action.uid,
                    resourceId: action.body.resourceId,
                    selectedCategory: action.body.selectedCategory,
                    status: action.status,
                },
                error: false,
                loading: false,
            };
        }

        case FETCH_DESIGNERS_FAILURE:
            return {
                ...state,
                response: {
                    body: action.body,
                    headers: action.headers,
                    status: action.status,
                    path: action.uid,
                },
                error: true,
                loading: false,
            };

        default:
            return state;
    }
}
