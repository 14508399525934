import { createStore, applyMiddleware, compose } from 'redux';
import { getStateFromCookies } from '@ynap/redux-cookies-middleware';
import reducers from './reducers';
import cookiePaths from './cookiePaths';

export default function configureStore(state = {}, middlewares = [], history) {
    let composeEnhancers = compose;

    /* istanbul ignore next */
    /* eslint-disable */

    if (__DEV__ && __CLIENT__) {
        if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
            composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
        }
    }

    const initialState = getStateFromCookies(state, cookiePaths);

    /* eslint-enable */
    const store = createStore(reducers(history), state, composeEnhancers(applyMiddleware(...middlewares)));

    // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
    /* istanbul ignore next */
    if (module.hot) {
        module.hot.accept('./reducers', () => {
            const nextRootReducer = require('./reducers').default;
            store.replaceReducer(nextRootReducer); // Need connectRouter to mount router state
        });
    }
    return store;
}
