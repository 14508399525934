export default (categories) => {
    return flatten(categories[0]);
};

function flatten(category) {
    if (!category) return [];
    const { seo = {}, label, identifier, labelEN } = category;
    const item = {
        label,
        path: seo.seoURLKeyword || '',
        identifier,
        labelEN,
    };
    return [item].concat(flatten(category.child));
}
