import {
    PRODUCT_LISTING_CONTENT_REQUEST,
    PRODUCT_LISTING_CONTENT_SUCCESS,
    PRODUCT_LISTING_CONTENT_FAILURE,
    PRODUCT_LISTING_SET_SINGLE_FILTER,
    PRODUCT_LISTING_REMOVE_SINGLE_FILTER,
    PRODUCT_LISTING_SET_MULTI_FILTER,
    PRODUCT_LISTING_REMOVE_MULTI_FILTER,
    PRODUCT_LISTING_UN_SELECT_ALL,
} from '../constants';

const initialState = {
    response: null,
    loading: false,
    error: false,
    singleFilters: [],
    multiFilters: [],
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case PRODUCT_LISTING_CONTENT_REQUEST:
            return {
                ...state,
                response: null,
                loading: true,
                error: false,
            };

        case PRODUCT_LISTING_SET_SINGLE_FILTER:
            return {
                ...state,
                singleFilters: state.singleFilters.concat([action.filter]),
                urlTag: false,
            };

        case PRODUCT_LISTING_SET_MULTI_FILTER:
            return {
                ...state,
                multiFilters: state.multiFilters.concat([action.filter]),
                urlTag: false,
            };

        case PRODUCT_LISTING_REMOVE_SINGLE_FILTER:
            return {
                ...state,
                singleFilters: state.singleFilters.slice(1),
            };

        case PRODUCT_LISTING_REMOVE_MULTI_FILTER:
            return {
                ...state,
                multiFilters: state.multiFilters.filter((value) => value.identifier !== action.filter.identifier),
            };

        case PRODUCT_LISTING_UN_SELECT_ALL:
            return {
                ...state,
                multiFilters: state.multiFilters.filter((value) => !action.appliedValues.includes(value.label)),
            };

        case PRODUCT_LISTING_CONTENT_SUCCESS:
            return {
                ...state,
                response: {
                    body: action.body,
                    headers: action.headers,
                    status: action.status,
                    coremediaPageKey: action.coremediaPageKey,
                    path: action.uid,
                },
                error: false,
                loading: false,
            };

        case PRODUCT_LISTING_CONTENT_FAILURE:
            return {
                ...state,
                response: {
                    body: action.body,
                    headers: action.headers,
                    status: action.status,
                    coremediaPageKey: action.coremediaPageKey,
                    path: action.uid,
                },
                error: true,
                loading: false,
            };

        default:
            return state;
    }
}
