import { isUserAllowed } from '@ynap/user-state';
export default function getLoading(
    listingLoading,
    forceLogIn = false,
    user,
    fetchingMore,
    restrictedToSegments,
    isPageVisible,
    isFetchingOptimizelyAlgorithmicSort,
) {
    if ((forceLogIn && (!user || !isUserAllowed(restrictedToSegments, user) || !isPageVisible)) || isFetchingOptimizelyAlgorithmicSort) {
        return {
            loadingRequest: true,
            pidOnly: false,
        };
    }

    return {
        loadingRequest: listingLoading,
        pidOnly: fetchingMore || listingLoading,
    };
}
