export default (selectedCategory, previewDate) => {
    //the listing is visible if there is no selectedCategory, such as search
    if (!selectedCategory) return true;

    const { visibility } = selectedCategory;
    //if no visibility, consider it visible (it's default for all listing)
    if (!visibility) return true;

    const { startDate, endDate } = visibility;
    if (!startDate || !endDate) return false;

    // preview date allows you to override current date
    const parsedPreviewDate = !isNaN(Date.parse(previewDate)) && new Date(previewDate);
    const currentDate = parsedPreviewDate ? parsedPreviewDate : new Date();
    const startD = new Date(startDate);
    const endD = new Date(endDate);

    return startD <= currentDate && currentDate <= endD;
};
