import { constructUrl } from '@ynap/url-configuration';
export default (pdpTemplate, locale, seoURLKeyword) => {
    return (
        seoURLKeyword &&
        constructUrl(pdpTemplate, {
            LOCALE: locale,
            SEO_URLKEYWORD: seoURLKeyword,
        })
    );
};
