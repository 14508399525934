import { PRODUCT_DETAILS_REQUEST, PRODUCT_DETAILS_SUCCESS, PRODUCT_DETAILS_FAILURE, RESET_STATE, RESET_PDP_STATE } from '../constants';

const initialState = {
    response: null,
    loading: false,
    error: false,
};

export default function response(state = initialState, action = {}) {
    switch (action.type) {
        case PRODUCT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case PRODUCT_DETAILS_SUCCESS:
            if (action.body.products && action.body.products.length) {
                return {
                    ...state,
                    response: {
                        body: action.body,
                        headers: action.headers,
                        status: action.status,
                        path: action.uid,
                        partNumber: action.options.partNumber,
                    },
                    loading: false,
                    error: false,
                };
            }
            return {
                ...state,
                response: {
                    body: action.body,
                    headers: action.headers,
                    status: 404,
                    path: action.uid,
                },
                loading: false,
                error: true,
            };

        case PRODUCT_DETAILS_FAILURE:
            return {
                ...state,
                response: {
                    body: action.body.error,
                    headers: action.headers,
                    status: action.status,
                    path: action.uid,
                },
                loading: false,
                error: true,
            };

        case RESET_PDP_STATE:
            return initialState;

        case RESET_STATE:
            return initialState;

        default:
            return state;
    }
}
