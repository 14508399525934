import thunkInject from 'redux-thunk-inject';
import wrapFetchWithLogger from '@ynap/logging-utils/src/wrapFetchWithLogger';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import cookiesMiddleware from '@ynap/redux-cookies-middleware';
import { createFetchWcs } from '@ynap/fetch';
import cookiePaths from './cookiePaths';

// __DEV__, __CLIENT__ are global webpack define plugin variables and will be removed at buildtime

export default function configureMiddleware({ storeRef, req, history }) {
    const middlewares = [];
    /* global __CLIENT__:true __DEV__:true*/ /* istanbul ignore next */
    if (__DEV__ && __CLIENT__) {
        // dev tools
        const clientFetchAndLog = createFetchWcs(wrapFetchWithLogger({ fetch, logger: console }), storeRef);
        middlewares.push(thunkInject(clientFetchAndLog));
        import('redux-logger').then((reduxLogger) => {
            const devLogger = reduxLogger.createLogger({
                logger: console,
                collapsed: true,
            });
            middlewares.push(thunkInject(devLogger));
        });
    } else if (__CLIENT__) {
        middlewares.push(thunkInject(createFetchWcs(fetch, storeRef)));
    } else if (req) {
        // server
        middlewares.push(thunkInject(req.app.get('fetchAndLog')));
    }

    const routerMiddleware = createRouterMiddleware(history);
    middlewares.push(routerMiddleware);

    middlewares.push(
        loadingBarMiddleware({
            promiseTypeSuffixes: ['PRODUCT_DETAILS/REQUEST', 'PRODUCT_DETAILS/SUCCESS', 'PRODUCT_DETAILS/FAILURE'],
        }),
    );

    middlewares.push(
        loadingBarMiddleware({
            promiseTypeSuffixes: ['PRODUCT_LISTING/REQUEST', 'PRODUCT_LISTING/SUCCESS', 'PRODUCT_LISTING/FAILURE'],
        }),
    );

    middlewares.push(
        loadingBarMiddleware({
            promiseTypeSuffixes: ['PRODUCT_LISTING_MORE/REQUEST', 'PRODUCT_LISTING_MORE/SUCCESS', 'PRODUCT_LISTING_MORE/FAILURE'],
        }),
    );

    middlewares.push(cookiesMiddleware(cookiePaths));

    return middlewares;
}
