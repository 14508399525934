import { CHANGE_SELECTED_PART_NUMBER } from '../constants';

export default (partNumber) => {
    return (dispatch) => {
        return dispatch({
            type: CHANGE_SELECTED_PART_NUMBER,
            partNumber,
        });
    };
};
