import { createComponent } from '@ycos/fela';
import { ratioToPadding } from '@ynap/image';

export const Gutter = createComponent('Gutter', ({ theme }) => ({
    maxWidth: `${theme.breakpoints.xlarge.width}px`, // Should come from Theme
    margin: '0 auto',
}));

export const ProductImage = createComponent('ProductItem', ({ theme, imageAR }) => ({
    display: 'block',
    width: '100%',
    paddingBottom: ratioToPadding(imageAR),
    marginBottom: `${2 * theme.spacingMultiplier}px`,
    backgroundColor: theme.skeleton.color,
}));
