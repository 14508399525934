import { getSrcAttrs } from '@ycos/picture/src/utils';
import { mediaQuery, breakpoints } from '@ycos/themes';
import queryString from 'query-string';

export function getContentHeader(headerData, contentTop, contentTopNav, config, messages, coremediaPageKey, contentLoading = false, country = '') {
    const { title, subTitle, pageType } = headerData;
    let newTitle = getTitle(headerData, contentTop);
    let newSubTitle = getSubTitle(headerData, contentTop);
    let saleTitle;
    let preTitle;
    const { picturesAndMedia = [], media } = contentTop || {};

    const tags = getTags(contentTop);
    const isSaleBanner = tags && tags.includes('salebanner');

    if (isSaleBanner) {
        newSubTitle = getPreTitle(headerData, contentTop);
        preTitle = getSubTitle(headerData, contentTop);
    } else {
        if (config?.head?.plp?.subTitleAsTitle) {
            // For NON DLPs only display lowest category as title
            if (!config?.head?.plp?.subTitleAsTitle.includes(pageType)) {
                newTitle = subTitle || title;
                newSubTitle = '';
            } else if (pageType.includes('MARK_DOWN')) {
                saleTitle = messages?.contentHeader?.sale({ country });
            }

            //show title from coremedia as full title if the content key matches the urlKeyword,
            //e.g. urlKeyword:/what-to-wear/the-summer-shop and content key: what-to-wear-the-summer-shop
            if (contentTop?.teaserTitle && comparePageAndContentKey(headerData, coremediaPageKey)) {
                newTitle = contentTop.teaserTitle;
                newSubTitle = '';
            }
        } else {
            // when subtitle exists, show title
            // otherwise show teaserTitle
            newTitle = subTitle ? title : newTitle;

            // when subTitle and teaserTitle exist, show teaserTitle
            // otherwise show subTitle
            newSubTitle = (subTitle && contentTop?.teaserTitle) || subTitle;
        }
        preTitle = getPreTitle(headerData, contentTop);
    }

    const copy = getCopy(contentTop);

    const subCategoryLinks = getSubCategoryLinks(contentTopNav);

    const banners = getBanners(contentTop, config);

    if (contentLoading) {
        return {
            title: newTitle,
            subTitle: newSubTitle,
            preTitle,
            seo: headerData.seo,
        };
    }

    return {
        contentTop,
        title: newTitle,
        subTitle: newSubTitle,
        preTitle,
        copy,
        banners,
        tags,
        seo: headerData.seo,
        saleTitle,
        subCategoryLinks,
        picturesAndMedia: media || picturesAndMedia,
    };
}

function comparePageAndContentKey(headerData, coremediaPageKey) {
    const urlKeyword = headerData?.seo?.seoURLKeyword;
    return urlKeyword && coremediaPageKey ? urlKeyword.substring(1).split('/').join('-').replace(/_/g, '-') === coremediaPageKey : false;
}

function getTitle(headerData, contentTop) {
    return contentTop?.teaser?.teaserTitle || contentTop?.teaserTitle || headerData.title;
}

function getSubTitle(headerData, contentTop) {
    return contentTop?.teaser?.teaserPreTitlePlain || contentTop?.preTitlePlain || headerData.subTitle;
}

function getPreTitle(headerData, contentTop) {
    return contentTop?.teaser?.teaserSubTitlePlain || contentTop?.subTitlePlain || headerData.preTitle;
}

function getCopy(contentTop) {
    return contentTop?.teaser?.teaserText || contentTop?.teaserText || '';
}

function getSubCategoryLinks(contentTopNav) {
    return contentTopNav?.teaser?.teaserText || contentTopNav?.teaserText || '';
}

function getBanners(contentTop, config) {
    const { availableContentImageSizes, imageQualityPercentage, contentBaseUrl, widthZero } = config?.head || [];
    const pictures = contentTop?.media?.[0] || contentTop?.picturesAndMedia?.[0];
    const imageUrl = pictures?.imageUrl;
    const srcset = availableContentImageSizes && imageUrl && generateSrcSetString(imageUrl, availableContentImageSizes, imageQualityPercentage, contentBaseUrl);
    return contentTop && pictures ? { ...pictures, isLegacy: contentTop?.layoutVariant === 'plp', srcset, widthZero } : null;
}

export function generateSrcSetString(imageUrl, availableImageSizes, imageQuality, contentBaseUrl) {
    const srcset = availableImageSizes.map((size) => {
        const src = generateSrcString(imageUrl, contentBaseUrl, size, imageQuality);
        return `${src} ${size}w`;
    });
    return srcset.join(', ');
}

function generateSrcString(imageUrl, contentBaseUrl, imageSize, imageQuality) {
    const quality = imageQuality ? `_q${imageQuality}` : '';
    const width = imageSize ? `/w${imageSize}` : '';
    const prepend = contentBaseUrl ? contentBaseUrl : '';
    return `${prepend}${imageUrl}${width}${width && quality}${width && '.jpg'}`;
}

function getTags(contentTop) {
    if (contentTop && contentTop.tags) {
        const tags = contentTop?.tags?.find((t) => t.name === 'product');

        if (tags) {
            return tags.children.map((productTags) => productTags.name);
        }
    }
    return null;
}

export function getContentImages(items = [], imageQualityPercentage) {
    let contentImagesMapped = [];
    const { medium } = mediaQuery.min(breakpoints);
    const { small } = mediaQuery.max(breakpoints);

    items.forEach((item) => {
        const pictures = item.media || item.picturesAndMedia || [];
        pictures.forEach((picture) => {
            if (picture.imageUrl) {
                const { src, srcSet } = getSrcAttrs(picture.imageUrl, imageQualityPercentage);
                contentImagesMapped.push(
                    { href: picture.imageUrl, imagesrcset: `${src} 2x`, as: 'image', media: small || '', fetchpriority: 'high' },
                    { href: picture.imageUrl, imagesrcset: srcSet, as: 'image', media: medium || '', fetchpriority: 'high' },
                );
            }
        });
    });
    return contentImagesMapped;
}

// Transform content state into 7 slots
export default function getContent(state) {
    const { response, error, loading } = state.plp.content;
    if (!response || error) return {};

    const content = response.body;

    if (content?.layoutVariant !== 'PLP') return { loading };

    const parsedUrl = state.plp.listing?.response?.path && queryString.parseUrl(state.plp.listing.response.path, { decode: false });

    const facetApplied = typeof parsedUrl?.query?.facet === 'string' ? parsedUrl?.query?.facet : null;

    const PLPContentFacetPlacement = content?.placements?.find(({ name }) => name === 'PLPContentFacet')?.content || content?.PLPContentFacet;

    const contentFacet =
        facetApplied && PLPContentFacetPlacement?.find?.((item) => item?.teaser?.ynapParameter === facetApplied || item?.ynapParameter === facetApplied);

    const seo = {
        title: contentFacet?.seoMetadata?.seoHTMLTitle || contentFacet?.seoHTMLTitle || content?.seoMetadata?.seoHTMLTitle || content?.seoHTMLTitle || '',
        metaDescription:
            contentFacet?.seoMetadata?.seoHTMLMetaDescription ||
            contentFacet?.seoHTMLMetaDescription ||
            content?.seoMetadata?.seoHTMLMetaDescription ||
            content?.seoHTMLMetaDescription ||
            '',
        metaKeyword: contentFacet?.seoMetadata?.freeKeywords || contentFacet?.freeKeywords || content?.seoMetadata?.freeKeywords || content?.freeKeywords || '',
    };

    if (contentFacet) {
        // Add a value we can check in canonical url generation
        contentFacet.facetContent = !!contentFacet;
    }

    const carouselContent = content?.PLPContentCarousel || {};
    let contentImagesToPreload = [];
    if (carouselContent.length) {
        const imageQualityPercentage = state?.config?.head?.imageQualityPercentage || 80;
        const carouselContentItems = carouselContent[0]?.items?.slice(0, state?.config?.head?.plp?.preloadedContentItems || carouselContent[0]?.items?.length);
        contentImagesToPreload = getContentImages(carouselContentItems || [], imageQualityPercentage);
    }

    if (content?.main?.[0]?.type === 'queryList' && content?.main?.[0]?.items) {
        // TODO: cover difference in productList vs queryList returning `items` in coremedia.
        delete content?.main?.[0]?.items;
    }

    if (!content?.placements) {
        return {
            // PLPContentTop, PLPContentTopNav, PLPContentCarousel, PLPContentSide, PLPContentBottom and PLPContentNoProducts and main are defined in coremedia
            loading,
            contentTop: contentFacet || content?.PLPContentTop?.[0],
            contentTopNav: content?.PLPContentTopNav?.[0],
            contentCarousel: content?.PLPContentCarousel,
            contentSide: content?.PLPContentSide?.[0],
            contentBottom: content?.PLPContentBottom,
            footerAd: content?.PLPFooterAd?.[0],
            emptyListContent: content?.PLPContentEmptyPage?.[0],
            contentNoProducts: content?.PLPContentNoProducts?.[0],
            contentMain: content?.main,
            seo,
            coremediaPageKey: response.coremediaPageKey,
            contentImagesToPreload,
        };
    }

    return {
        // PLPContentTop, PLPContentTopNav, PLPContentCarousel, PLPContentSide, PLPContentBottom and PLPContentNoProducts and main are defined in coremedia
        loading,
        contentTop: contentFacet || content?.placements?.find(({ name }) => name === 'PLPContentTop')?.content?.[0],
        contentTopNav: content?.placements?.find(({ name }) => name === 'PLPContentTopNav')?.content?.[0],
        contentCarousel: content?.placements?.find(({ name }) => name === 'PLPContentCarousel')?.content,
        contentSide: content?.placements?.find(({ name }) => name === 'PLPContentSide')?.content?.[0],
        contentBottom: content?.placements?.find(({ name }) => name === 'PLPContentBottom')?.content,
        footerAd: content?.placements?.find(({ name }) => name === 'PLPFooterAd')?.content?.[0],
        emptyListContent: content?.placements?.find(({ name }) => name === 'PLPContentEmptyPage')?.content?.[0],
        contentNoProducts: content?.placements?.find(({ name }) => name === 'PLPContentNoProducts')?.content?.[0],
        contentMain: content?.placements?.find(({ name }) => name === 'main')?.content,
        seo,
        coremediaPageKey: response.coremediaPageKey,
        contentImagesToPreload,
    };
}
