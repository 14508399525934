import { SET_ERROR, RESET_STATE } from './constants';

const initialState = {
    error: null,
    info: null,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_ERROR:
            return action.value;
        case RESET_STATE:
            return initialState;
        default:
            return state;
    }
}
