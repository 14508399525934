// redux constants
export const PRODUCT_LISTING_REQUEST = 'PRODUCT_LISTING/REQUEST';
export const PRODUCT_LISTING_SUCCESS = 'PRODUCT_LISTING/SUCCESS';
export const PRODUCT_LISTING_FAILURE = 'PRODUCT_LISTING/FAILURE';

export const PRODUCT_SIBLINGS_REQUEST = 'PRODUCT_SIBLINGS/REQUEST';
export const PRODUCT_SIBLINGS_SUCCESS = 'PRODUCT_SIBLINGS/SUCCESS';
export const PRODUCT_SIBLINGS_FAILURE = 'PRODUCT_SIBLINGS/FAILURE';

export const PRODUCT_LISTING_CONTENT_REQUEST = 'PRODUCT_LISTING_CONTENT/REQUEST';
export const PRODUCT_LISTING_CONTENT_SUCCESS = 'PRODUCT_LISTING_CONTENT/SUCCESS';
export const PRODUCT_LISTING_CONTENT_FAILURE = 'PRODUCT_LISTING_CONTENT/FAILURE';
export const PRODUCT_LISTING_SET_SINGLE_FILTER = 'PRODUCT_LISTING/SET_SINGLE_FILTER';
export const PRODUCT_LISTING_REMOVE_SINGLE_FILTER = 'PRODUCT_LISTING/REMOVE_SINGLE_FILTER';
export const PRODUCT_LISTING_SET_MULTI_FILTER = 'PRODUCT_LISTING/SET_MULTI_FILTER';
export const PRODUCT_LISTING_REMOVE_MULTI_FILTER = 'PRODUCT_LISTING/REMOVE_MULTI_FILTER';
export const PRODUCT_LISTING_UN_SELECT_ALL = 'PRODUCT_LISTING/UN_SELECT_ALL';

export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';

export const UPDATE_SELECTED_SCHEMA = 'UPDATE_SELECTED_SCHEMA';

// non-redux constants
// filter types
export const MULTI_SELECT = 'MULTI_SELECT';
export const SCHEMA_SELECT = 'SCHEMA_SELECT';
export const SINGLE_SELECT = 'SINGLE_SELECT';

export const RESET_STATE = 'RESET_STATE';
export const RESET_PLP_STATE = 'RESET_PLP_STATE';

export const EIP_PREVIEW = 'EIP_PREVIEW';

export const PRODUCT_LISTING_MORE_REQUEST = 'PRODUCT_LISTING_MORE/REQUEST';
export const PRODUCT_LISTING_MORE_SUCCESS = 'PRODUCT_LISTING_MORE/SUCCESS';
export const PRODUCT_LISTING_MORE_FAILURE = 'PRODUCT_LISTING_MORE/FAILURE';

export const SET_NO_SET_SCROLL_ON_PLP = 'SET_NO_SET_SCROLL_ON_PLP';
export const SET_SCROLL_ON_PLP = 'SET_SCROLL_ON_PLP';

export const UNSET_UPDATE_PAGENUMBER = 'UNSET_UPDATE_PAGENUMBER';

export const UNSET_SCROLL = 'UNSET_SCROLL';
export const RECYCLEPIDS = 'RECYCLEPIDS';
export const SET_POSITION = 'SET_POSITION';

export const AUTHENTICATED = 'AUTHENTICATED';

export const PRODUCT_LISTING_FOUNDIT_REQUEST = 'PRODUCT_LISTING_FOUNDIT/REQUEST';
export const PRODUCT_LISTING_FOUNDIT_SUCCESS = 'PRODUCT_LISTING_FOUNDIT/SUCCESS';
export const PRODUCT_LISTING_FOUNDIT_FAILURE = 'PRODUCT_LISTING_FOUNDIT/FAILURE';

export const SET_OPTIMIZELY_ALGORITHMIC_SORT_READY = 'SET_OPTIMIZELY_ALGORITHMIC_SORT_READY';
