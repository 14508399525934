export default function getLoading(state, props) {
    const { pdp: pdpState } = state;
    const { response } = pdpState.detailsState;
    const { colourPartNumbers, loading } = pdpState.appState;
    //set to loading state
    //if there are no response
    if (!response) {
        return true;
    }
    //or if loading is true and response path not the same as url and color variant does not belong to the current part number
    const { path } = response;
    return (
        loading && decodeURI(path) !== decodeURI(props.location.pathname + props.location.search) && !colourPartNumbers.includes(props.match.params.PART_NUMBER)
    );
}
