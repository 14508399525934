import { combineReducers, composeReducers } from '@ynap/reducer-utils';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { authReducer } from '@ynap/auth-state';
import { addToCartReducer } from '@ynap/cart-state';
import { combinedPlpReducer } from './product-listing-state';
import { composedPDPReducer } from './product-details-state';
import { combinedDazReducer } from './designeraz-state';
import { productReducers } from './product-state';
import { addToWishlistReducer, navigationReducer } from '@ynap/wishlist-state';
import { userFetchedFromSFReducer } from '@ynap/user-state';
import { notificationReducer } from '@ynap/notification-state';
import { recommendations } from '@ynap/product-details-state';

export default (history) =>
    composeReducers(
        combineReducers({
            auth: authReducer,
            cart: addToCartReducer,
            config: productReducers.config,
            appState: productReducers.appState,
            plp: combinedPlpReducer,
            pdp: composedPDPReducer,
            daz: combinedDazReducer,
            error: productReducers.error,
            loadingBar: loadingBarReducer,
            addToWishlist: addToWishlistReducer,
            userState: userFetchedFromSFReducer,
            notificationState: notificationReducer,
            fetchWishlists: navigationReducer,
            router: connectRouter(history),
            recommendations: recommendations,
        }),
    );

export function batchDispatch(functions, store) {
    const toDispatch = functions.map((f) => store.dispatch(f));
    return Promise.all(toDispatch);
}
