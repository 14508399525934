export const trackPageView = async (props) => {
    const { trackPage, tracking, updateAnalytics, routeKey } = props;

    if (!trackPage || !tracking) return;

    return (
        trackPage(props)
            .then((result) => {
                tracking.trackEvent(result);
                updateAnalytics && updateAnalytics(result, routeKey);
                // Trigger Navigation event can be listened to in Site Furniture.
                window.dispatchEvent(
                    new CustomEvent('spa-navigation', {
                        detail: {
                            url: result?.page?.pageInfo?.destinationURL,
                        },
                    }),
                );
            })
            // TODO: Pass catch error to clientside monitoring
            .catch(
                /* istanbul ignore next - No need to test catch promise */ (e) => {
                    // eslint-disable-next-line no-console
                    console.log('analytics error', e);
                },
            )
    );
};

export const useCategoryIdentifier = (category) => category?.labelEN || category?.label;
