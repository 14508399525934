import { safeBuildEventObj } from '@ynap/analytics';
import { raygun } from '@ynap/clientside-monitoring';

const CATEGORY_FACET_ID = 'CATEGORIES';
const STANDARD_PAGE_TYPE = 'STANDARD';
const WHATS_NEW_PAGE_TYPE = 'WHATS_NEW_LAST_WEEK';
const DESIGNER_PAGE_TYPE = 'DESIGNER';
const MARK_DOWN_DESIGNER_PAGE_TYPE = 'MARK_DOWN_DESIGNER';
const MARK_DOWN_PAGE_TYPE = 'MARK_DOWN';
const CUSTOM_LIST_PAGE_TYPE = 'CUSTOM LIST';
const JUST_IN_PAGE_TYPE = 'JUST_IN';
const EIP_PREVIEW = 'EIP_PREVIEW';

export default function getCategoryLabels(...args) {
    return safeBuildEventObj(getCategoryLabelsInternal, ...args);
}

function getCategoryLabelsInternal(state, selectedCategories, pageType = STANDARD_PAGE_TYPE, facetConfig, isTONMens) {
    const { response, error } = state.plp.listing;
    const { identifierToLabel, useIdentifier } = state.config.head || {};
    const { facets, products } = response?.body || {};

    if (!facets && products) {
        const tagMessage = `response.body.facets is null/not defined`;
        raygun.helpers.agent('send', {
            error: new Error(`getCategoryLabels error`),
            tags: [`error-getCategoryLabels`, `${tagMessage}`],
            customData: error ? error : selectedCategories,
        });
    }
    if (!response || error) return null;

    const categoryLevels = getCategoryLevels(pageType);

    return getCategoryLabelsArr(selectedCategories, pageType, categoryLevels, response?.body?.facets, identifierToLabel, facetConfig, useIdentifier, isTONMens);
}

// Using facets we can determine how many category levels there are just like in getFilters.js
export const getCategoryLevels = (pageType) => {
    return pageType === STANDARD_PAGE_TYPE ? 2 : 3;
};

//to parse identifier like 'UKME__Accessories', 'UKME__List_The_Best_Sellers' and "UKME__Burberry_DESIGNER"
export const getCategoryIdValue = (identifier) => {
    let value = identifier;
    if (value.includes('__')) {
        value = identifier.split('__')[1];
    }

    return value.replace('_DESIGNER', '').replace('List_', '').replace('_MENS', '').toLowerCase();
};

// TODO: ISPW-1187 - remove when the analytics enricher handles localised analytics for PLP.
export const getCategoryLabelFromIdentifier = (category, identifierToLabel, pageType, isTopLevelCategory = false, useIdentifier) => {
    let label;
    if (identifierToLabel) {
        if (isTopLevelCategory) {
            switch (pageType) {
                case DESIGNER_PAGE_TYPE:
                case MARK_DOWN_DESIGNER_PAGE_TYPE:
                case CUSTOM_LIST_PAGE_TYPE:
                    label = useIdentifier ? getCategoryIdValue(category?.identifier) : category?.identifier?.split('/')?.[2];
                    break;
                case JUST_IN_PAGE_TYPE:
                case EIP_PREVIEW:
                    label = useIdentifier ? getCategoryIdValue(category?.identifier) : category?.identifier?.split('/')?.[1];
                    break;
                default:
                    label = category?.identifier?.split('__')?.[1];
                    break;
            }
        } else if (category?.identifier?.includes('__')) {
            label = category?.identifier?.split('__')?.[1];
        } else if (category?.identifier?.includes('_2_')) {
            label = category?.identifier?.split('_2_')[1].split('_')[0];
        } else if (category?.identifier?.includes('_')) {
            label = category?.identifier?.split('_')?.[1];
        }
    } else {
        label = category?.label;
    }
    return label && label !== 'list' ? label?.replace(/[-_]/g, ' ').toLowerCase() : '';
};

/*
A few examples of how the selected category labels need to be tranformed
'Gucci'                             -> [ 'Designer', 'Gucci', 'all', 'all' ]
'whats new'                         -> [ 'whats new - this week', 'all', 'all' ]
'whats new', 'clothing'             -> [ 'whats new - this week', 'clothing', 'all' ]
'clothing'                          -> [ 'category list', 'clothing', 'all' ]
'clothing', 'Blazers'               -> [ 'category list', 'clothing', 'blazers' ]
'clothing', 'Blazers', 'blazers1'   -> [ 'category list', 'clothing', 'blazers' ]
*/
export const getCategoryLabelsArr = (s, pageType, categoryLevels = 2, facets, identifierToLabel, facetConfig, useIdentifier, isTONMens) => {
    // this corrects the API, we should still get the facets here when there are no products.
    // it seems to just grab the facets of the first product rather than understand where
    // it is in some hierarchy properly.
    if (s && pageType && !facets) {
        // if first category label is List (too generic) then use second category label
        const nonListLabel = s?.[0]?.label === 'List' ? s?.[1]?.label : s?.[0]?.label;
        const designerName = nonListLabel || 'unknown';
        let catsList;

        if (pageType === WHATS_NEW_PAGE_TYPE) {
            catsList = [`whats new - ${designerName.toLowerCase()}`];
        } else if (pageType === MARK_DOWN_DESIGNER_PAGE_TYPE) {
            catsList = [`designer - ${designerName.toLowerCase()}`];
        } else {
            catsList = [`${pageType.toLowerCase()} - ${designerName}`];
        }

        // we padd the array with 'all' for analytics because the API returns bad data
        for (let i = 1; i < categoryLevels; i++) {
            catsList.push('all');
        }
        return catsList;
    }

    if (!s || !facets) {
        return [];
    }

    const ignoreSale = pageType === MARK_DOWN_PAGE_TYPE && facetConfig?.ignoreCategories?.includes('/sale');
    let selectedCategories = [...s];
    let categoryLabels = [];
    let categoryFacet = facets.find((facet) => facet.identifier === CATEGORY_FACET_ID);

    // selects next level facet if mens
    if (isTONMens && pageType !== DESIGNER_PAGE_TYPE) {
        const categorySeletor = pageType === CUSTOM_LIST_PAGE_TYPE ? categoryFacet.entry?.[0]?.children?.[0]?.children : categoryFacet.entry?.[0]?.children;
        const mensCategoryFacet = categorySeletor?.find((item) => item?.seo?.seoURLKeyword === s[0]?.seoURLKeyword);
        categoryFacet = {
            entry: [mensCategoryFacet],
        };
    }

    let selected = {
        label: '',
        children: [],
        identifier: '',
    };
    let onlyChild = false;

    if (categoryLevels === 3) {
        // if multiple selectedCategories do not use "List" as too generic
        const correctSelectedCategory = selectedCategories[0].label === 'List' && selectedCategories[1] ? selectedCategories[1] : selectedCategories[0];
        const isStandardPage = pageType === STANDARD_PAGE_TYPE;
        categoryLabels.push(getCategoryLabelFromIdentifier(correctSelectedCategory, identifierToLabel, pageType, true, !isStandardPage));
        selectedCategories.shift();
    }

    if (categoryFacet?.entry.length === 1) {
        selected = categoryFacet.entry[0];
        categoryLabels.push(getCategoryLabelFromIdentifier(categoryFacet?.entry[0], identifierToLabel, pageType, false, useIdentifier));
    } else {
        const topCatIdentifier = selectedCategories[0]?.identifier;
        selected = categoryFacet?.entry.find((c) => c.identifier === topCatIdentifier) || { label: '', children: [], identifier: '' };
        categoryLabels.push(
            getCategoryLabelFromIdentifier(selected, identifierToLabel, pageType, false, useIdentifier) ||
                getCategoryLabelFromIdentifier(selectedCategories?.[0], identifierToLabel, pageType, false, useIdentifier) ||
                'all',
        );
    }

    const isCustomList = pageType === CUSTOM_LIST_PAGE_TYPE && selected?.label === 'List';
    const children = isCustomList ? selected?.children?.[0]?.children?.length : selected?.children?.length;
    onlyChild = children === 1;

    // transform into array of labels
    // append categories with label: 'all' for remaining unselected singe categories
    for (let i = 1; i < 2; i++) {
        const currentLabel = getCategoryLabelFromIdentifier(selectedCategories?.[i], identifierToLabel, pageType, false, useIdentifier);
        const currentIdentifier = selectedCategories?.[i]?.identifier;

        if (onlyChild) {
            categoryLabels.push(getCategoryLabelFromIdentifier(selected?.children?.[0], identifierToLabel, pageType, false, useIdentifier));
            onlyChild = selected?.children?.length === 1;
            selected = selected?.children?.[0];
            if (i < 1) {
                i++;
            }
            continue;
        }
        const selectedCategory = selected?.children?.find((c) => c.identifier === currentIdentifier);
        if (selectedCategory) {
            onlyChild = selected?.children?.length === 1;
            selected = selectedCategory.children;
        }
        !ignoreSale && categoryLabels.push(currentLabel || 'all');
    }

    //destructure the array and transform the top level category label
    const [topLevelLabel, ...rest] = deTopLevelLabelFromChildrenLabels(categoryLabels);
    const topLevelLabels = transformTopLevel(dePunctuate(topLevelLabel), pageType, ignoreSale);
    const filteredRest = rest.filter((item) => item && item !== 'list' && item !== 'mens');
    const result = [...topLevelLabels, ...filteredRest];
    return result;
};

const dePunctuate = (label) => label.replace("'", '');

export const deTopLevelLabelFromChildrenLabels = ([topLevelLabel, ...rest]) => [
    topLevelLabel,
    ...rest.map((label) => (label.indexOf(topLevelLabel) > -1 && label !== 'all' ? label.replace(topLevelLabel, '').trim() : label)),
];

const transformTopLevel = (currentLabel, pageType, ignoreSale) => {
    switch (pageType) {
        case STANDARD_PAGE_TYPE:
            return ['category list', currentLabel];
        case JUST_IN_PAGE_TYPE:
            return [`${currentLabel} - this week`];
        case CUSTOM_LIST_PAGE_TYPE:
            return [`custom list - ${currentLabel}`];
        case MARK_DOWN_PAGE_TYPE:
            return ignoreSale ? [`category list - ${currentLabel}`] : ['category list'];
        case MARK_DOWN_DESIGNER_PAGE_TYPE:
        case DESIGNER_PAGE_TYPE:
            return [`designer - ${currentLabel}`];
        case WHATS_NEW_PAGE_TYPE:
            return [`whats new - ${currentLabel}`];
        default:
            return [currentLabel];
    }
};
